<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import some from 'lodash/some';
import throttle from 'lodash/throttle';
import values from 'lodash/values';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { DELAY, exportFile, MILLIS_PER_SECOND } from '@emobg/web-utils';
import { MIME_TYPES } from '@domains/Invoicing/const/mimeTypes';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { INVOICE_STATUS } from '@domains/Invoicing/const/INVOICE_STATUS';
import { INVOICE_TYPES } from '@domains/Invoicing/const/INVOICE_TYPES';
import { setDocumentTitle } from '@domains/Main/titles.utils';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { scopes } from '../../store/InvoiceModule';

import InvoiceMigrationStatementComponent from './components/InvoiceMigrationStatement/InvoiceMigrationStatementComponent';
import InvoiceActionsComponent from './components/InvoiceActions/InvoiceActionsComponent';
import InvoiceIssuerComponent from './components/InvoiceIssuer/InvoiceIssuerComponent';
import InvoiceIdentificationComponent from './components/InvoiceIdentification/InvoiceIdentificationComponent';
import InvoiceCustomerComponent from './components/InvoiceCustomer/InvoiceCustomerComponent';
import InvoiceBookingComponent from './components/InvoiceBooking/InvoiceBookingComponent';
import InvoiceServiceLineComponent from './components/InvoiceServiceLine/InvoiceServiceLineComponent';
import InvoiceTotalsComponent from './components/InvoiceTotals/InvoiceTotalsComponent';
import InvoicePaymentsComponent from './components/InvoicePayments/InvoicePaymentsComponent';
import InvoiceRefundableServiceLineComponent from './components/InvoiceServiceLine/InvoiceRefundableServiceLineComponent';

import ServiceLineModal from './components/InvoiceServiceLine/ServiceLineModal';
import InvoiceCloseModal from './components/InvoiceCloseModal';
import InvoiceChangeCustomerModal from './components/InvoiceChangeCustomerModal';
import CreditNoteCreationConfirmationModal from './components/CreditNoteCreationConfirmationModal';
import CreditNoteDraftCloseModal from './components/CreditNoteDraftCloseModal';

const expiryParameterOnUrl = 'X-Goog-Expires';
const timeframeExpirationParameterInSeconds = 60;

export default {
  name: 'InvoiceDetailsView',

  components: {
    CreditNoteCreationConfirmationModal,
    CreditNoteDraftCloseModal,
    InvoiceActionsComponent,
    InvoiceBookingComponent,
    InvoiceChangeCustomerModal,
    InvoiceCloseModal,
    InvoiceCustomerComponent,
    InvoiceIdentificationComponent,
    InvoiceIssuerComponent,
    InvoiceMigrationStatementComponent,
    InvoicePaymentsComponent,
    InvoiceRefundableServiceLineComponent,
    InvoiceServiceLineComponent,
    InvoiceTotalsComponent,
    ServiceLineModal,
  },

  inject: ['$labels'],

  data() {
    return {
      invoiceUuid: null,
      isServiceModalOpen: false,
      isInvoiceCloseModalOpen: false,
      isInvoiceDraftCloseModalOpen: false,
      isChangeCustomerModalOpen: false,
      isDraftCreditNoteConfirmationModalOpen: false,
      isCreditNoteDraftCloseModalOpen: false,
      dowloadMigratedFileName: null,
      hasZipFile: false,
      hasExpiredInvoiceDownloadUrl: false,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),

    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoadingInvoice: state => get(state, 'invoices.STATUS.LOADING'),

      isLoadingDeleteService: state => get(state, 'serviceLine.STATUS.LOADING'),

      rawInvoicePDF: state => get(state, 'invoicePdf.data'),
      isLoadingPdf: state => get(state, 'invoicePdf.STATUS.LOADING'),

      isLoadingCustomerAddress: state => get(state, 'customerAddress.STATUS.LOADING'),

      isLoadingCreatingCreditNote: state => get(state, 'creditNote.STATUS.LOADING'),

      invoiceMediaUrl: state => get(state, 'invoiceMedia.data.data.url'),
      isInvoiceMediaUrlLoading: state => get(state, 'invoiceMedia.STATUS.LOADING'),
      hasInvoiceMediaUrlError: state => get(state, 'invoiceMedia.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, [
      'createdCreditNoteUuid',
      'draftCreditNoteUuid',
      'hasBookings',
      'hasRefundableBookings',
      'hasRefundableServiceLinesWithoutBooking',
      'hasRefundableServiceLinesWithBooking',
      'hasServiceLinesWithoutBooking',
      'invoiceData',
      'customerData',
      'isDraftCreditNote',
      'hasServiceLines',
      'getRefundableServicesByBookingId',
      'getServicesByBookingId',
      'serviceData',
      'refundableServiceData',
      'draftServiceData',
      'hasPayments',
    ]),

    invoiceId: ({ invoiceData }) => get(invoiceData, 'id'),
    invoiceType: ({ invoiceData }) => get(invoiceData, 'serieType'),
    invoiceStatus: ({ invoiceData }) => get(invoiceData, 'status'),
    invoiceSerie: ({ invoiceData }) => get(invoiceData, 'serie'),
    invoiceNumber: ({ invoiceData }) => get(invoiceData, 'number'),
    customerName: ({ customerData }) => get(customerData, 'name'),

    isProforma: ({ invoiceType }) => invoiceType === INVOICE_TYPES.proforma,
    isDeliveryNote: ({ invoiceType }) => invoiceType === INVOICE_TYPES.deliveryNote,
    isInvoice: ({ invoiceType }) => invoiceType === INVOICE_TYPES.invoice,
    isCreditNote: ({ invoiceType }) => invoiceType === INVOICE_TYPES.cancellation,
    isMigrated: ({ invoiceType }) => invoiceType === INVOICE_TYPES.migration,

    isOpen: ({ invoiceStatus }) => invoiceStatus === INVOICE_STATUS.open,
    isClosing: ({ invoiceStatus }) => invoiceStatus === INVOICE_STATUS.closing,
    isClosed: ({ invoiceStatus }) => invoiceStatus === INVOICE_STATUS.closed,

    isValidStatus: ({ invoiceStatus }) => includes(values(INVOICE_STATUS), invoiceStatus),

    isOpenProformaOrDelivery() {
      return this.isOpen && (this.isProforma || this.isDeliveryNote) && !this.isDraftCreditNote;
    },

    isClosedInvoice() {
      return this.isClosed && this.isInvoice;
    },

    isTotalRefunded() {
      return isEmpty(this.refundableServiceData);
    },

    isPDFAllowed() {
      return this.isClosed && !this.isMigrated;
    },

    isCreateCreditNoteAllowed() {
      return this.isClosedInvoice && !this.isTotalRefunded;
    },

    isUpdateAddressAllowed() {
      return (this.isOpen || this.isClosed) && !this.isMigrated;
    },

    closeInvoiceEvent: ({ isOpen }) => (isOpen ? 'click' : null),

    isLoading() {
      return some(
        [
          this.isLoadingInvoice,
          this.isLoadingPdf,
          this.isLoadingCustomerAddress,
          this.isLoadingCreatingCreditNote,
          this.isLoadingDeleteService,
          this.isInvoiceMediaUrlLoading,
        ],
      );
    },
  },

  watch: {
    rawInvoicePDF(rawPdf) {
      exportFile(rawPdf, `${this.invoiceSerie}-${this.invoiceNumber}.pdf`, { type: MIME_TYPES.applicationPdf });
    },
  },

  created() {
    this.NOTIFICATION_TYPES = NOTIFICATION_TYPES;
    this.invoiceUuid = get(this.$route, 'params.invoiceUuid');
    this.loadInvoice();
  },
  updated() {
    throttle(() => {
      setDocumentTitle(`${this.invoiceData.reference} - ${this.customerName}`);
    }, DELAY.extraLong)();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, [
      'getInvoiceByUuid',
      'putSyncCustomerAddress',
      'getInvoicePdf',
      'postCreditNote',
      'patchCreditNote',
      'getInvoiceDownloadUrl',
    ]),

    ...mapMutations(DOMAINS_MODEL.invoicing.invoices, [
      'clearErrors',
    ]),

    async loadInvoice() {
      await this.getInvoiceByUuid({ invoiceUuid: this.invoiceUuid });

      if (this.isMigrated) {
        this.retrieveInvoiceDownloadUrl();
      }
    },

    async retrieveInvoiceDownloadUrl() {
      await this.getInvoiceDownloadUrl(this.invoiceUuid);

      if (!this.hasInvoiceMediaUrlError) {
        this.hasZipFile = true;
        this.hasExpiredInvoiceDownloadUrl = false;

        const urlParameters = new URL(this.invoiceMediaUrl).searchParams;

        const urlExpires = urlParameters.get(expiryParameterOnUrl);

        const timeOutExpiryMediaUrl = (urlExpires - timeframeExpirationParameterInSeconds) * MILLIS_PER_SECOND;

        setTimeout(() => {
          this.hasExpiredInvoiceDownloadUrl = true;
        }, timeOutExpiryMediaUrl);
      } else {
        this.clearErrors({ scope: scopes.invoiceMedia });
      }
    },

    async onDownloadMigratedInvoices() {
      if (this.hasExpiredInvoiceDownloadUrl) {
        await this.retrieveInvoiceDownloadUrl();
      }

      const link = document.createElement('a');
      link.href = this.invoiceMediaUrl;
      link.click();
    },

    async onUpdateCustomerAddress() {
      await this.putSyncCustomerAddress(this.invoiceId);
      this.loadInvoice();
    },

    addService() {
      this.isServiceModalOpen = true;
    },

    onCloseServiceLineModal() {
      this.isServiceModalOpen = false;
    },

    onInvoiceClose() {
      if (this.isDraftCreditNote) {
        if (isEmpty(this.serviceData)) {
          this.$notify({
            message: this.$labels.InvoiceDetailsTab.credit_note.errors.no_draft_services_to_refund,
            textAction: 'Dismiss',
            type: NOTIFICATION_TYPES.error,
          });
        } else {
          this.isInvoiceDraftCloseModalOpen = true;
        }
      } else {
        this.isInvoiceCloseModalOpen = true;
      }
    },

    onCloseInvoiceCloseModal() {
      this.isInvoiceCloseModalOpen = false;
    },

    onCloseDraftCreditNoteModal() {
      this.isInvoiceDraftCloseModalOpen = false;
    },

    onCloseChangeCustomerModal() {
      this.isChangeCustomerModalOpen = false;
    },

    onShowPDF() {
      this.getInvoicePdf(this.invoiceId);
    },

    onChangeCustomer() {
      this.isChangeCustomerModalOpen = true;
    },

    async onRequestCreateDraftCreditNote() {
      if (this.draftCreditNoteUuid) {
        this.openDraftCreditNote(this.draftCreditNoteUuid);
      } else {
        this.isDraftCreditNoteConfirmationModalOpen = true;
      }
    },

    onCloseCreditNoteCreationConfirmationModal() {
      this.isDraftCreditNoteConfirmationModalOpen = false;
    },

    async onCreateCreditNoteDraft() {
      if (this.draftCreditNoteUuid) {
        this.openDraftCreditNote(this.draftCreditNoteUuid);
      } else {
        await this.postCreditNote({ invoiceUuid: this.invoiceUuid });
        this.openDraftCreditNote(this.createdCreditNoteUuid);
        this.loadInvoice();
      }
    },

    openDraftCreditNote(draftCreditNoteUuid) {
      window.open(`/invoicing/invoices/${draftCreditNoteUuid}/summary/details`, '_blank');
    },

    async onUpdateDraftCreditNote({ serviceId, units, isToRefund }) {
      const draftService = find(this.draftServiceData, ['linkedInvoiceLineId', serviceId]);
      draftService.units = units;
      draftService.isToRefund = isToRefund;

      const payload = {
        invoice: {
          invoiceLines: map(filter(this.draftServiceData, { isToRefund: true }), updatedDraftService => ({
            linkedInvoiceLineId: updatedDraftService.linkedInvoiceLineId,
            units: updatedDraftService.units,
          })),
        },
      };

      await this.patchCreditNote({
        invoiceUuid: this.invoiceUuid,
        payload,
      });

      this.loadInvoice();
    },

    onCloseCreditNoteDraftCloseModal() {
      this.isCreditNoteDraftCloseModalOpen = false;
    },
  },
};
</script>

<template>
  <div class="InvoiceDetailsView mx-auto">
    <InvoiceMigrationStatementComponent
      v-if="isMigrated"
      :show-download-button="hasZipFile"
      data-test-id="migration_statement"
      @on:download-migrated-invoices="onDownloadMigratedInvoices"
    />

    <div
      class="InvoiceDetailsView__content emobg-background-color-white emobg-border-1 emobg-border-color-ground overflow-hidden p-4 pb-5"
      data-test-id="invoice_details-view"
    >
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <div class="d-flex flex-wrap">
        <InvoiceActionsComponent
          :is-change-customer-allowed="isOpenProformaOrDelivery"
          :is-pdf-allowed="isPDFAllowed"
          :is-create-credit-note-allowed="isCreateCreditNoteAllowed"
          :is-valid-status="isValidStatus"
          :invoice-status="invoiceStatus"
          :close-invoice-event="closeInvoiceEvent"
          class="w-100 mb-4"
          @on:close="onInvoiceClose"
          @on:pdf="onShowPDF"
          @on:change-customer="onChangeCustomer"
          @on:create-credit-note="onRequestCreateDraftCreditNote"
          @on:change="loadInvoice"
        />

        <InvoiceIssuerComponent class="col-6 my-2" />

        <div class="col-6 my-2 text-right">
          <InvoiceIdentificationComponent class="mb-3" />

          <InvoiceCustomerComponent
            :is-update-address-allowed="isUpdateAddressAllowed"
            @on:update-customer-address="onUpdateCustomerAddress"
            @on:change="loadInvoice"
          />
        </div>

        <div class="col-12 mb-4 align-items-center">
          <h5
            v-if="isValidStatus"
            class="height--size-m d-inline-block"
          >
            {{ $labels.InvoiceDetailsTab.service_lines.title }}
          </h5>

          <ui-button
            v-if="isOpenProformaOrDelivery"
            :face="FACES.outline"
            class="float-right"
            data-test-id="add-service-button"
            @clickbutton="addService"
          >
            {{ $labels.InvoiceDetailsTab.service_lines.actions.add }}
          </ui-button>

          <InvoiceBookingComponent
            v-if="hasBookings || hasRefundableBookings"
            :is-edit-allowed="isOpenProformaOrDelivery"
            @on:change="loadInvoice"
            @update:draft="onUpdateDraftCreditNote"
          />

          <InvoiceRefundableServiceLineComponent
            v-else-if="isDraftCreditNote"
            class="w-100 mb-6"
            @update:draft="onUpdateDraftCreditNote"
          />

          <InvoiceServiceLineComponent
            v-else
            :is-edit-allowed="isOpenProformaOrDelivery"
            :invoice-id="invoiceId"
            class="w-100 mb-6"
            @on:change="loadInvoice"
          />
        </div>

        <InvoiceTotalsComponent class="offset-6 col-6 mb-4" />

        <InvoicePaymentsComponent
          v-if="!isDraftCreditNote && (hasServiceLines || hasPayments)"
          class="col-12"
          @on:change="loadInvoice"
        />
      </div>

      <ServiceLineModal
        v-if="isOpenProformaOrDelivery"
        :is-modal-open="isServiceModalOpen"
        :title="$labels.InvoiceDetailsTab.service_lines.modal.add_title"
        is-add-mode
        @on:close="onCloseServiceLineModal"
        @on:change="loadInvoice"
      />

      <InvoiceCloseModal
        v-if="isOpen"
        :is-modal-open="isInvoiceCloseModalOpen"
        @on:close="onCloseInvoiceCloseModal"
        @on:change="loadInvoice"
      />

      <InvoiceChangeCustomerModal
        v-if="isOpenProformaOrDelivery"
        :is-modal-open="isChangeCustomerModalOpen"
        @on:close="onCloseChangeCustomerModal"
        @on:change="loadInvoice"
      />

      <CreditNoteCreationConfirmationModal
        v-if="isClosedInvoice"
        :is-modal-open="isDraftCreditNoteConfirmationModalOpen"
        @on:close="onCloseCreditNoteCreationConfirmationModal"
        @on:create-credit-note-draft="onCreateCreditNoteDraft"
      />

      <CreditNoteDraftCloseModal
        v-if="isDraftCreditNote"
        :is-modal-open="isInvoiceDraftCloseModalOpen"
        @on:close="onCloseDraftCreditNoteModal"
        @on:change="loadInvoice"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .InvoiceDetailsView {
    max-width: 210mm;

    &__content {
      min-height: 66rem;
    }
  }
</style>
