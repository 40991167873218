<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'InvoiceIdentificationComponent',

  inject: ['$labels'],

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'isDraftCreditNote']),

    serieType() {
      const invoiceType = `${get(this, 'invoiceData.serieType')}${this.isDraftCreditNote ? '_cl' : ''}`;

      return this.$labels.InvoiceDetailsTab.types[invoiceType];
    },
  },
};
</script>

<template>
  <div
    v-if="serieType"
    data-test-id="invoice_customer-component"
  >
    <h5 class="text-uppercase mb-2">
      {{ serieType }}
    </h5>

    <div class="emobg-font-default emobg-font-weight-bold mb-2">
      {{ invoiceData.serie }}-{{ invoiceData.number }}
    </div>

    <div class="emobg-color-ink-light mb-2">
      {{ invoiceData.dateStr }}
    </div>
  </div>
</template>
