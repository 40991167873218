<script>
import map from 'lodash/map';
import range from 'lodash/range';

export default {
  name: 'InvoiceRefundableServiceLineComponent',

  inject: ['$labels'],

  props: {
    draftServices: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    range,

    requestForChangeInDraft(serviceId, units, isToRefund) {
      this.$emit('update:draft', {
        serviceId,
        units,
        isToRefund,
      });
    },

    selectUnits(units) {
      const options = range(-1, units - 1, -1);
      return map(options, (value) => ({ label: value, value }));
    },
  },
};
</script>

<template>
  <div
    class="InvoiceRefundableServiceLineComponent"
    data-test-id="invoice_refundable-service_line-component"
  >
    <table class="w-100">
      <thead>
        <tr class="emobg-color-ink-light emobg-border-bottom-2 emobg-border-color-ground-light align-items-center d-flex py-3">
          <th class="col-1 pl-0" />

          <th class="text-left col-5">
            {{ $labels.InvoiceDetailsTab.service_lines.service }}
          </th>

          <th class="text-right col-2">
            {{ $labels.InvoiceDetailsTab.service_lines.units }}
          </th>

          <th class="text-right col-2">
            {{ $labels.InvoiceDetailsTab.service_lines.price }}
          </th>

          <th class="text-right col-2">
            {{ $labels.InvoiceDetailsTab.service_lines.total }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(service, index) in draftServices"
          :key="index"
          class="emobg-caption-1 emobg-border-bottom-1 emobg-border-color-ink-lighter align-items-center d-flex"
        >
          <td class="col-1 pl-0">
            <ui-checkbox
              :checked="service.isToRefund"
              :data-test-id="`refundable-service-checkbox-${index}`"
              name="is_to_refund"
              @changevalue="({ detail }) => {
                service.isToRefund = detail;
                requestForChangeInDraft(service.id, service.unitsToRefund, detail)
              }"
            />
          </td>

          <td
            class="col-5 align-top"
            :class="{'emobg-color-ink-light': !service.refund }"
          >
            {{ service.name }}

            <div
              v-if="service.description"
              class="emobg-color-ink-light mt-1"
            >
              {{ service.description }}
            </div>
          </td>

          <td class="text-right col-2 py-3">
            <ui-select
              :value.prop="service.unitsToRefund"
              :options.prop="selectUnits(service.inversedUnits)"
              :disabled="!service.isToRefund"
              :data-test-id="`units-select-${index}`"
              name="units"
              @selectoption="({ detail }) => requestForChangeInDraft(service.id, detail, service.isToRefund)"
            />
          </td>

          <td
            class="text-right col-2 "
            :class="{'emobg-color-ink-light': !service.refund }"
          >
            {{ service.amount.valueStr }}
          </td>

          <td
            class="text-right col-2"
            :class="{'emobg-color-ink-light': !service.refund }"
          >
            {{ service.grossAmountStr }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.InvoiceRefundableServiceLineComponent {
  min-height: 6em;
}
</style>
