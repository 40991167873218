var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      staticClass: "InvoicePaymentsModal",
      attrs: {
        title: _vm.$labels.InvoiceDetailsTab.payments.modal.retry.title,
        header: _vm.headerObject,
        "data-test-id": "payment-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-wrap py-3",
          attrs: { slot: "body" },
          slot: "body",
        },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("div", { staticClass: "col-6 mt-4 pl-0 pr-2" }, [
            _c("label", { staticClass: "emobg-caption-2 d-block mb-1" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.payments.modal.payment_method
                  ) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "emobg-caption-1 d-block mt-1" }, [
              _vm._v(" " + _vm._s(_vm.previousPaymentData.methodName) + " "),
            ]),
          ]),
          _c("div", { staticClass: "col-6 mt-4 pl-2 pr-0" }, [
            _c("label", { staticClass: "emobg-caption-2 d-block mb-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$labels.InvoiceDetailsTab.payments.modal.date) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "emobg-caption-1 d-block mt-1" }, [
              _vm._v(" " + _vm._s(_vm.previousPaymentData.dateStr) + " "),
            ]),
          ]),
          _c("div", { staticClass: "col-12 mt-4 pl-0 pr-2" }, [
            _c("label", { staticClass: "emobg-caption-2 d-block mb-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$labels.InvoiceDetailsTab.payments.modal.amount) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "emobg-caption-1 d-block mt-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.get(_vm.previousPaymentData, "amount.valueStr")) +
                  " "
              ),
            ]),
            !_vm.isRetryAllowed
              ? _c(
                  "div",
                  {
                    staticClass:
                      "emobg-font-x-small emobg-font-weight-normal emobg-color-danger mt-1",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$labels.InvoiceDetailsTab.payments.modal.retry
                            .pending_greater_than_amount
                        ) +
                        " " +
                        _vm._s(_vm.pendingAmountStr) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("MuiTextarea", {
            staticClass: "col-12 mt-4 px-0",
            attrs: {
              placeholder:
                _vm.$labels.InvoiceDetailsTab.payments.modal
                  .comments_placeholder,
              label: _vm.$labels.InvoiceDetailsTab.payments.modal.comments,
              name: "description",
              rows: "4",
              "data-test-id": "description-input",
            },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: !_vm.isRetryAllowed,
                "data-test-id": "retry-button",
              },
              on: { clickbutton: _vm.onRetryPayment },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.payments.modal.retry.action
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }