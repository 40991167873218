var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customerData.name
    ? _c(
        "div",
        {
          staticClass: "InvoiceCustomerComponent",
          attrs: { "data-test-id": "invoice_customer-view" },
        },
        [
          _c("h5", { staticClass: "text-uppercase mb-2" }, [
            _vm._v(
              " " + _vm._s(_vm.$labels.InvoiceDetailsTab.customer.title) + " "
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "emobg-link-primary emobg-body-2 mb-2",
              attrs: {
                href: _vm.customerLink,
                target: "_blank",
                "data-test-id": "customer_details-link",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.customerData.name) + " "),
              _c("ui-icon", {
                staticClass: "ml-1",
                attrs: {
                  icon: _vm.ICONS.externalUrl,
                  size: _vm.ICONS_SIZES.small,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
            _vm._v(" " + _vm._s(_vm.customerData.taxNumber) + " "),
          ]),
          _vm.customerData.street1
            ? _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.customerData.street1) +
                    " " +
                    _vm._s(_vm.customerData.street2) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.customerData.city) +
                " " +
                _vm._s(_vm.customerData.postalCode) +
                " "
            ),
          ]),
          _vm.customerData.phone
            ? _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$labels.InvoiceDetailsTab.customer.phone_abbr) +
                    ": " +
                    _vm._s(_vm.customerData.phone) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isUpdateAddressAllowed
            ? _c(
                "ui-button",
                {
                  staticClass: "float-right align-bottom mb-2 pr-0 mr-n3",
                  attrs: {
                    face: _vm.FACES.text,
                    "data-test-id": "sync_address-button",
                  },
                  on: {
                    clickbutton: function ($event) {
                      return _vm.$emit("on:update-customer-address")
                    },
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "pr-2",
                    attrs: {
                      icon: _vm.ICONS.refresh,
                      size: _vm.ICONS_SIZES.medium,
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$labels.InvoiceDetailsTab.customer.update_address
                      ) +
                      " "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }