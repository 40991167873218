var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoiceId
    ? _c(
        "MuiModal",
        {
          attrs: {
            size: _vm.SIZES.small,
            "data-test-id": "invoice_close-modal",
          },
          on: {
            "modal-closed": function ($event) {
              return _vm.$emit("on:close")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "body",
                fn: function () {
                  return [
                    _vm.isLoading
                      ? _c("ui-loader", {
                          attrs: { fixed: "", "data-test-id": "loader" },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large px-3 mt-5 mb-4",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$labels.InvoiceDetailsTab.close.confirmation
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "emobg-font-default emobg-color-ink-light px-3 mb-5",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.invoiceSerie) +
                            "-" +
                            _vm._s(_vm.invoiceNumber) +
                            " (" +
                            _vm._s(_vm.invoiceDate) +
                            ") "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            219708237
          ),
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("CancelButton", {
                attrs: { "data-test-id": "cancel-button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("on:close")
                  },
                },
              }),
              _c("ConfirmButton", {
                attrs: { "data-test-id": "confirm-button" },
                on: { click: _vm.onInvoiceClose },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }