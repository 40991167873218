<script>
import get from 'lodash/get';
import template from 'lodash/template';
import { reformatDateTime } from '@emobg/web-utils';

import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

export default {
  name: 'InvoiceMigrationStatementComponent',

  inject: ['$labels'],

  props: {
    showDownloadButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'isDraftCreditNote']),
    invoiceDate: ({ invoiceData }) => get(invoiceData, 'date'),
  },

  created() {
    this.NOTIFICATION_TYPES = NOTIFICATION_TYPES;

    this.migrationStatement_1 = template(this.$labels.InvoiceDetailsTab.migration.statement_1)({
      date: reformatDateTime(this.invoiceDate, DATETIME_FORMATS.LongMonthYear),
    });

    this.migrationStatement_2 = this.$labels.InvoiceDetailsTab.migration.statement_2;

    this.downloadMigratedInvoices = this.$labels.InvoiceDetailsTab.migration.download_migrated_invoices;
  },
};
</script>

<template>
  <div class="emobg-background-color-white emobg-border-1 emobg-border-color-ground p-4">
    <ui-alert
      :color="COLORS.warning"
      :icon="ICONS.alertFull"
      class="d-block mx-2 mb-4"
      data-test-id="migration_statement"
    >
      <div>
        {{ migrationStatement_1 }}
      </div>
      <div>
        {{ migrationStatement_2 }}
      </div>
    </ui-alert>

    <ui-button
      v-show="showDownloadButton"
      data-test-id="invoice-dowload-button"
      @clickbutton="$emit('on:download-migrated-invoices')"
    >
      {{ downloadMigratedInvoices }}
    </ui-button>
  </div>
</template>
