<script>
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'InvoiceIssuerComponent',

  inject: ['$labels'],

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['issuerData']),
  },
};
</script>

<template>
  <div
    v-if="issuerData"
    class="InvoiceIssuerComponent"
    data-test-id="invoice_issuer-component"
  >
    <img
      :src="issuerData.logoUrl"
      class="InvoiceIssuerComponent__logo mb-4"
      alt="Issuer logo"
    >

    <h5 class="emobg-font-default mb-2">
      {{ issuerData.name }}
    </h5>

    <div class="emobg-color-ink-light mb-2">
      {{ issuerData.fiscalNumber }}
    </div>

    <div class="emobg-color-ink-light mb-2">
      {{ issuerData.addressStreet }}
    </div>

    <div class="emobg-color-ink-light mb-2">
      {{ issuerData.addressCity }}
    </div>

    <div
      v-if="issuerData.addressPhone"
      class="emobg-color-ink-light mb-2"
    >
      {{ $labels.InvoiceDetailsTab.issuer.phone_abbr }}: {{ issuerData.addressPhone }}
    </div>

    <div
      v-if="issuerData.registrationNumber"
      class="emobg-color-ink-light mb-2"
    >
      {{ $labels.InvoiceDetailsTab.issuer.vat_abbr }}: {{ issuerData.registrationNumber }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.InvoiceIssuerComponent {
  min-height: 20em;

  &__logo {
    width: 150px;
  }
}
</style>
