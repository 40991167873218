var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "emobg-background-color-white emobg-border-1 emobg-border-color-ground p-4",
    },
    [
      _c(
        "ui-alert",
        {
          staticClass: "d-block mx-2 mb-4",
          attrs: {
            color: _vm.COLORS.warning,
            icon: _vm.ICONS.alertFull,
            "data-test-id": "migration_statement",
          },
        },
        [
          _c("div", [_vm._v(" " + _vm._s(_vm.migrationStatement_1) + " ")]),
          _c("div", [_vm._v(" " + _vm._s(_vm.migrationStatement_2) + " ")]),
        ]
      ),
      _c(
        "ui-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDownloadButton,
              expression: "showDownloadButton",
            },
          ],
          attrs: { "data-test-id": "invoice-dowload-button" },
          on: {
            clickbutton: function ($event) {
              return _vm.$emit("on:download-migrated-invoices")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.downloadMigratedInvoices) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }