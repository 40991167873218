<script>
import get from 'lodash/get';
import includes from 'lodash/includes';
import size from 'lodash/size';
import { mapGetters } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { INVOICE_STATUS } from '@domains/Invoicing/const/INVOICE_STATUS';
import { INVOICE_TYPES } from '@domains/Invoicing/const/INVOICE_TYPES';
import { INVOICE_PAYMENT_ACTION } from '@domains/Invoicing/const/INVOICE_PAYMENT_ACTIONS';
import { INVOICE_PAYMENT_STATUS } from '@domains/Invoicing/const/INVOICE_PAYMENT_STATUS';
import { INVOICE_PAYMENT_METHODS } from '@domains/Invoicing/const/INVOICE_PAYMENT_METHODS';

import InvoicePaymentsAddModal from './InvoicePaymentsAddModal';
import InvoicePaymentsEditModal from './InvoicePaymentsEditModal';
import InvoicePaymentsDeleteModal from './InvoicePaymentsDeleteModal';
import InvoicePaymentsRetryModal from './InvoicePaymentsRetryModal';
import InvoicePaymentsRefundModal from './InvoicePaymentsRefundModal';

export default {
  name: 'InvoicePaymentsComponent',

  components: {
    InvoicePaymentsAddModal,
    InvoicePaymentsEditModal,
    InvoicePaymentsDeleteModal,
    InvoicePaymentsRetryModal,
    InvoicePaymentsRefundModal,
  },

  inject: ['$labels'],

  data() {
    return {
      isAddModalOpen: false,
      isDeleteModalOpen: false,
      isEditModalOpen: false,
      isRetryModalOpen: false,
      isRefundModalOpen: false,
      paymentId: undefined,
      paymentTransactionUuid: null,
    };
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['paymentsData', 'invoiceStatus', 'invoiceType', 'isDraftCreditNote']),

    isAddPaymentAllowed() {
      return includes([INVOICE_STATUS.open, INVOICE_STATUS.closed], this.invoiceStatus)
        && this.invoiceType !== INVOICE_TYPES.cancellation
        && !this.isDraftCreditNote;
    },

    isMigrated() {
      return this.invoiceType === INVOICE_TYPES.migration;
    },
  },

  methods: {
    get,
    size,
    isEditAllowed(payment) {
      return payment.internal
        && !(this.isMigrated && this.isPaymentCompensation(payment))
        && !this.isPaymentNegativeCompensation(payment)
        && !this.isPaymentPending(payment.status)
        && !this.isPaymentFailed(payment.status);
    },

    isPaymentCompensation(payment) {
      return payment.methodId === INVOICE_PAYMENT_METHODS.compensation;
    },

    isPaymentNegativeCompensation(payment) {
      const amount = get(payment, 'amount.value') / 100;

      return this.isPaymentCompensation(payment) && amount < 0;
    },

    isDeleteAllowed(payment) {
      return payment.internal
        && !(this.isMigrated && this.isPaymentCompensation(payment))
        && !this.isPaymentPending(payment.status)
        && !this.isPaymentFailed(payment.status);
    },

    isRefundAllowed(payment) {
      return !this.isPaymentRefunded(payment.action)
        && !this.isPaymentPending(payment.status)
        && !this.isPaymentFailed(payment.status)
        && (this.isPaymentCard(payment.methodId)
        || this.isSepa(payment.methodId));
    },

    isRetryAllowed() {
      /* this is a temporal workaround until we find a better solution to deal with retries in BO.
      see https://europcarmobility.atlassian.net/browse/CSREV-3817
      see also https://europcarmobility.atlassian.net/browse/CSREV-3777?focusedCommentId=516152
      */
      return false;
    },

    isPaymentCard(methodId) {
      return methodId === INVOICE_PAYMENT_METHODS.paymentCard;
    },

    isSepa(methodId) {
      return methodId === INVOICE_PAYMENT_METHODS.sepa;
    },

    isPaymentRefunded(action) {
      return action === INVOICE_PAYMENT_ACTION.refund;
    },

    isPaymentFailed(status) {
      return status === INVOICE_PAYMENT_STATUS.failed;
    },

    isPaymentPending(status) {
      return status === INVOICE_PAYMENT_STATUS.pending;
    },

    isPaymentSuccess(status) {
      return status === INVOICE_PAYMENT_STATUS.success;
    },

    areActionsAllowed(payment) {
      return this.invoiceType !== INVOICE_TYPES.cancellation
        && (this.isEditAllowed(payment)
        || this.isDeleteAllowed(payment)
        || this.isRefundAllowed(payment)
        || this.isRetryAllowed(payment));
    },

    editOption({ id }) {
      return {
        label: this.$labels.InvoiceDetailsTab.payments.actions.edit,
        action: () => {
          this.paymentId = id;
          this.isEditModalOpen = true;
        },
      };
    },

    refundOption({ id, uuid }) {
      return {
        label: this.$labels.InvoiceDetailsTab.payments.actions.refund,
        action: () => {
          this.paymentId = id;
          this.paymentTransactionUuid = uuid;
          this.isRefundModalOpen = true;
        },
      };
    },

    retryOption({ id }) {
      return {
        label: this.$labels.InvoiceDetailsTab.payments.actions.retry,
        action: () => {
          this.paymentId = id;
          this.isRetryModalOpen = true;
        },
      };
    },

    deleteOption({ id }) {
      return {
        label: this.$labels.InvoiceDetailsTab.payments.actions.delete,
        labelClass: 'emobg-color-danger',
        action: () => {
          this.paymentId = id;
          this.isDeleteModalOpen = true;
        },
      };
    },

    actions(payment) {
      const options = [];

      if (this.isEditAllowed(payment)) {
        options.push(this.editOption(payment));
      }

      if (this.isRefundAllowed(payment)) {
        options.push(this.refundOption(payment));
      }

      if (this.isRetryAllowed(payment)) {
        options.push(this.retryOption(payment));
      }

      if (this.isDeleteAllowed(payment)) {
        options.push(this.deleteOption(payment));
      }

      return options;
    },

    onCloseAddModal() {
      this.isAddModalOpen = false;
    },

    onCloseEditModal() {
      this.isEditModalOpen = false;
      this.paymentId = undefined;
    },

    onCloseDeleteModal() {
      this.isDeleteModalOpen = false;
      this.paymentId = undefined;
    },

    onCloseRetryModal() {
      this.isRetryModalOpen = false;
      this.paymentId = undefined;
    },

    onCloseRefundModal() {
      this.isRefundModalOpen = false;
      this.paymentId = undefined;
    },

    onAddPayment() {
      this.isAddModalOpen = true;
    },

    statusStyle(status) {
      return ({
        [INVOICE_PAYMENT_STATUS.success]: 'emobg-border-color-success-lighter emobg-background-color-success-lightest',
        [INVOICE_PAYMENT_STATUS.pending]: 'emobg-border-color-warning-lighter emobg-background-color-warning-lightest',
        [INVOICE_PAYMENT_STATUS.failed]: 'emobg-border-color-danger-lighter emobg-background-color-danger-lightest',
      })[status];
    },
  },
};
</script>

<template>
  <div
    v-if="size(paymentsData) || isAddPaymentAllowed"
    class="InvoicePaymentsComponent flex-wrap"
    data-test-id="invoice_payments-component"
  >
    <h5 class="height--size-m d-inline-block">
      {{ $labels.InvoiceDetailsTab.payments.payments }}
    </h5>

    <ui-button
      v-if="isAddPaymentAllowed"
      :face="FACES.outline"
      class="float-right mb-2"
      data-test-id="add-button"
      @clickbutton="onAddPayment"
    >
      {{ $labels.InvoiceDetailsTab.payments.add_payment }}
    </ui-button>

    <table class="w-100">
      <thead>
        <tr class="emobg-color-ink-light emobg-border-bottom-2 emobg-border-color-ground-light align-items-center d-flex pt-3 pb-1">
          <th
            class="col-2 mr-2 pl-0 text-left"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.date }}
          </th>

          <th
            class="w-20 mr-2 text-left"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.method }}
          </th>

          <th
            class="w-10 mr-2 text-right"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.amount }}
          </th>

          <th
            class="w-10 mr-2 text-left"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.provider }}
          </th>

          <th
            class="w-20 mr-2 text-left"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.reference }}
          </th>

          <th
            class="w-10"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.status }}
          </th>

          <th
            class="w-10 ml-2 text-left"
            scope="col"
          >
            {{ $labels.InvoiceDetailsTab.payments.comment }}
          </th>

          <th
            class="size-medium square"
            scope="col"
          />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="payment in paymentsData"
          :key="payment.id"
          class="emobg-caption-1 emobg-border-bottom-1 emobg-border-color-ink-lighter align-items-center d-flex"
        >
          <td class="col-2 py-3 mr-2 pl-0">
            {{ payment.dateStr }}
          </td>

          <td class="w-20 mr-2">
            {{ payment.methodName }}
          </td>

          <td class="w-10 mr-2 text-right">
            {{ get(payment, 'amount.valueStr') }}
          </td>

          <td class="w-10 mr-2">
            {{ payment.provider || FALLBACK_MESSAGE.dash }}
          </td>

          <td class="w-20 mr-2">
            {{ payment.transactionRef || FALLBACK_MESSAGE.dash }}
          </td>

          <td
            class="w-10 mr-2 emobg-border-1 emobg-border-radius-pill text-center text-capitalize"
            :class="statusStyle(payment.status)"
          >
            {{ payment.status || FALLBACK_MESSAGE.dash }}
          </td>

          <td class="w-10 overflow-hidden">
            <ui-tooltip
              v-if="payment.description"
              :tooltip="payment.description"
              :placement="PLACEMENTS.topEnd"
              class="align-middle"
            >
              <p class="InvoicePaymentsComponent__comment ellipsis">
                {{ payment.description }}
              </p>
            </ui-tooltip>
            <span v-else>
              {{ FALLBACK_MESSAGE.dash }}
            </span>
          </td>

          <td>
            <ui-dropdown
              v-if="areActionsAllowed(payment)"
              :placement="PLACEMENTS.left"
              class="mt-2"
            >
              <ui-icon
                slot="trigger"
                :icon="ICONS.optionsHorizontalFull"
                :color="GRAYSCALE.inkLight"
              />
              <ui-dropdown-actions
                slot="content"
                :actions.prop="actions(payment)"
                data-test-id="dropdown-actions"
              />
            </ui-dropdown>
            <div
              v-else
              class="size-medium square"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <InvoicePaymentsAddModal
      :is-modal-open="isAddModalOpen"
      v-on="$listeners"
      @on:close="onCloseAddModal"
    />

    <InvoicePaymentsEditModal
      :is-modal-open="isEditModalOpen"
      :payment-id="paymentId"
      v-on="$listeners"
      @on:close="onCloseEditModal"
    />

    <InvoicePaymentsDeleteModal
      :is-modal-open="isDeleteModalOpen"
      :payment-id="paymentId"
      v-on="$listeners"
      @on:close="onCloseDeleteModal"
    />

    <InvoicePaymentsRetryModal
      :is-modal-open="isRetryModalOpen"
      :payment-id="paymentId"
      v-on="$listeners"
      @on:close="onCloseRetryModal"
    />

    <InvoicePaymentsRefundModal
      :is-modal-open="isRefundModalOpen"
      :payment-id="paymentId"
      :payment-transaction-uuid="paymentTransactionUuid"
      v-on="$listeners"
      @on:close="onCloseRefundModal"
    />
  </div>
</template>

<style lang="scss" scoped>
.InvoicePaymentsComponent {
  &__comment {
    width: 110px;
  }
}
</style>
