<script>
export default {
  name: 'InvoiceActionsComponent',

  inject: ['$labels'],

  props: {
    isChangeCustomerAllowed: {
      type: Boolean,
      default: false,
    },
    isPdfAllowed: {
      type: Boolean,
      default: false,
    },
    isCreateCreditNoteAllowed: {
      type: Boolean,
      default: false,
    },
    isValidStatus: {
      type: Boolean,
      default: false,
    },
    invoiceStatus: {
      type: String,
      default: '',
    },
    closeInvoiceEvent: {
      type: String,
      default: null,
    },
  },

  computed: {
    statusClass() {
      return this.invoiceStatus === 'open' ? 'cursor-pointer emobg-background-color-success' : 'emobg-background-color-moderate';
    },
  },
};
</script>

<template>
  <div
    class="InvoiceActionsComponent position-relative"
    data-test-id="invoice_actions-component"
  >
    <ui-button
      :face="FACES.outline"
      :color="GRAYSCALE.inkLight"
      square
      class="m-1"
      data-test-id="reload-button"
      @clickbutton="$emit('on:change')"
    >
      <ui-icon
        :color="COLORS.inkLight"
        :icon="ICONS.refresh"
        :size="ICONS_SIZES.large"
      />
    </ui-button>

    <ui-button
      v-if="isChangeCustomerAllowed"
      :face="FACES.outline"
      class="m-1"
      data-test-id="change_customer-button"
      @clickbutton="$emit('on:change-customer')"
    >
      {{ $labels.InvoiceDetailsTab.actions.change_customer }}
    </ui-button>

    <ui-button
      v-if="isPdfAllowed"
      :face="FACES.outline"
      :color="GRAYSCALE.inkLight"
      square
      class="m-1"
      data-test-id="pdf-button"
      @clickbutton="$emit('on:pdf')"
    >
      <ui-icon
        :color="COLORS.inkLight"
        :icon="ICONS.filePdf"
        :size="ICONS_SIZES.large"
      />
    </ui-button>

    <ui-button
      v-if="isCreateCreditNoteAllowed"
      :color="COLORS.danger"
      class="m-1"
      data-test-id="credit_note-button"
      @clickbutton="$emit('on:create-credit-note')"
    >
      {{ $labels.InvoiceDetailsTab.actions.create_credit_note }}
    </ui-button>

    <div
      v-if="isValidStatus"
      :class="['InvoiceActionsComponent__status position-absolute emobg-color-white text-uppercase align-center', `${statusClass}`]"
      :data-status="invoiceStatus"
      data-test-id="close-button"
      @[closeInvoiceEvent]="$emit('on:close')"
    >
      {{ $labels.InvoiceDetailsTab.actions.status[invoiceStatus] }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.InvoiceActionsComponent {
  min-height: 4em;

  &__status {
    top: -40px;
    right: -40px;
    z-index: 1;
    width: 170px;
    padding: 12px 0;
    transform: rotate(45deg) translate(30%);
  }
}
</style>
