<script>
import get from 'lodash/get';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'InvoiceCustomerComponent',

  inject: ['$labels'],

  props: {
    isUpdateAddressAllowed: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['customerData']),

    customerLink() {
      return get(this, 'customerData.companyId')
        ? companyProfileUrl(get(this, 'customerData.companyUuid'))
        : `/user-company/users/${get(this, 'customerData.userUuid')}/invoicing`;
    },
  },
};
</script>

<template>
  <div
    v-if="customerData.name"
    class="InvoiceCustomerComponent"
    data-test-id="invoice_customer-view"
  >
    <h5 class="text-uppercase mb-2">
      {{ $labels.InvoiceDetailsTab.customer.title }}
    </h5>

    <a
      :href="customerLink"
      target="_blank"
      class="emobg-link-primary emobg-body-2 mb-2"
      data-test-id="customer_details-link"
    >
      {{ customerData.name }}
      <ui-icon
        :icon="ICONS.externalUrl"
        :size="ICONS_SIZES.small"
        class="ml-1"
      />
    </a>

    <div class="emobg-color-ink-light mb-2">
      {{ customerData.taxNumber }}
    </div>

    <div
      v-if="customerData.street1"
      class="emobg-color-ink-light mb-2"
    >
      {{ customerData.street1 }} {{ customerData.street2 }}
    </div>

    <div class="emobg-color-ink-light mb-2">
      {{ customerData.city }} {{ customerData.postalCode }}
    </div>

    <div
      v-if="customerData.phone"
      class="emobg-color-ink-light mb-2"
    >
      {{ $labels.InvoiceDetailsTab.customer.phone_abbr }}: {{ customerData.phone }}
    </div>

    <ui-button
      v-if="isUpdateAddressAllowed"
      :face="FACES.text"
      class="float-right align-bottom mb-2 pr-0 mr-n3"
      data-test-id="sync_address-button"
      @clickbutton="$emit('on:update-customer-address')"
    >
      <ui-icon
        :icon="ICONS.refresh"
        :size="ICONS_SIZES.medium"
        class="pr-2"
      />
      {{ $labels.InvoiceDetailsTab.customer.update_address }}
    </ui-button>
  </div>
</template>

<style lang="scss" scoped>
.InvoiceCustomerComponent {
  min-height: 11em;
}
</style>
