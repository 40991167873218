<script>
import { cookie as cookieManager } from '@emobg/web-utils';

import get from 'lodash/get';
import template from 'lodash/template';
import toNumber from 'lodash/toNumber';
import { mapActions, mapGetters, mapState } from 'vuex';

import { MuiModal } from '@emobg/motion-ui/v1';

import { Validate } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';

import { NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN } from '@/utils';

export default {
  name: 'InvoicePaymentsRefundModal',

  components: {
    CancelButton,
    MuiModal,
  },

  directives: { Validate },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    paymentId: {
      type: Number,
      default: undefined,
    },
    paymentTransactionUuid: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      isOpen: false,
      isFormValid: false,
      amount: undefined,
      description: undefined,
      minAmount: 0.01,
      maxAmount: 0,
      maxTextAreaLength: 150,
      textAreaCharsCount: 150,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoicePayments.STATUS.LOADING'),
      isError: state => get(state, 'invoicePayments.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['getPaymentById']),
  },

  watch: {
    isModalOpen(newValue) {
      this.isOpen = newValue;

      if (newValue) {
        this.clearData();
      }
    },

    paymentId(newValue) {
      if (newValue) {
        this.getPaymentData();
      }
    },
  },

  created() {
    this.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN = NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN;

    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };

    this.csrevFeatureFlags = cookieManager.get('csrevFeatureFlags', true) ? JSON.parse(cookieManager.get('csrevFeatureFlags', true)) : null;

    this.isV6Version = this.csrevFeatureFlags ? this.csrevFeatureFlags['CSREV-3687-bo-refund-invoice'] : false;

    this.textAreaCharsCount = this.maxTextAreaLength;
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['postRefundInvoicePayment', 'postRefundInvoicePaymentTransaction']),

    template,

    getPaymentData() {
      const previousPaymentData = this.getPaymentById(this.paymentId);

      this.maxAmount = get(previousPaymentData, 'amount.value') / 100;
    },

    async onRefundPayment() {
      if (this.isV6Version && this.paymentTransactionUuid) {
        const amountNoDecimals = this.amount.replace(/[,.`']/g, '.');
        const adjustedAmount = (Number(amountNoDecimals).toFixed(2)) * 100;

        const payload = {
          amount: adjustedAmount,
          description: this.description,
        };

        await this.postRefundInvoicePaymentTransaction({
          transactionUuid: this.paymentTransactionUuid,
          payload,
        });
      } else {
        const payload = {
          amount: this.amount.toString(),
          description: this.description,
        };

        await this.postRefundInvoicePayment({
          paymentId: this.paymentId,
          payload,
        });
      }

      this.$emit('on:close');

      if (!this.isError) {
        this.showSuccessfullyAction(this.$labels.InvoiceDetailsTab.payments.modal.refund.successfully);
        this.$emit('on:change');
      }
    },

    showSuccessfullyAction(message) {
      this.$notify({
        message,
        textAction: '',
      });
    },

    isAmountValid(value) {
      const currentAmount = toNumber(value);

      return this.minAmount <= currentAmount && currentAmount <= this.maxAmount;
    },

    clearData() {
      this.amount = undefined;
      this.description = undefined;
      this.textAreaCharsCount = this.maxTextAreaLength;
    },

    textAreaManager(text) {
      this.description = text.detail;
      this.textAreaCharsCount = this.maxTextAreaLength - text.detail.length;
    },

    isFormAllValid(value) {
      const textAreaLenghtIsValid = this.textAreaCharsCount >= 0;

      this.isFormValid = value.detail.isValid && value.detail.areAllValidated && textAreaLenghtIsValid;
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="$labels.InvoiceDetailsTab.payments.modal.refund.title"
    :header="headerObject"
    class="InvoicePaymentsModal"
    data-test-id="payment-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <ui-validate
        ref="validationWrapper"
        class="d-flex flex-wrap mx-3"
        @status="isFormAllValid"
      >
        <ui-text-input
          v-validate.input="{
            isRequired: true,
            isPattern: {
              pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
              message: $labels.InvoiceDetailsTab.payments.modal.amount_format_error,
            },
            isAmountOutOfRange: (value) => {
              const limits = { min: minAmount, max: maxAmount };

              return {
                isValid: isAmountValid(value),
                message: template($labels.InvoiceDetailsTab.payments.modal.refund.amount_out_of_range)(limits),
              };
            },
          }"
          :value="amount"
          :type="INPUT_TYPES.number"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.amount}*`"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.amount_placeholder"
          name="amount"
          class="col-12 mt-4 px-0"
          data-test-id="amount-input"
          @changevalue="({ detail }) => amount = detail"
        />

        <ui-text-area
          v-validate.input="{
            isRequired: true,
            isMinLength: {
              message: 'This field cannot be blank',
              length: 1,
            },
            isMaxLength: {
              message: `The number of characters allowed is ${maxTextAreaLength}`,
              length: maxTextAreaLength,
            },
          }"
          :value="description"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.comments_placeholder"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.comments}* (${textAreaCharsCount} characters left)`"
          name="description"
          rows="4"
          class="col-12 mt-4 px-0"
          data-test-id="description-input"
          :maxlength="maxTextAreaLength"
          @changevalue="textAreaManager"
        />
      </ui-validate>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ui-button
        :disabled="!isFormValid"
        data-test-id="refund-button"
        @clickbutton="onRefundPayment"
      >
        {{ $labels.InvoiceDetailsTab.payments.modal.refund.action }}
      </ui-button>
    </div>
  </MuiModal>
</template>
