var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      attrs: {
        title: _vm.$labels.InvoiceDetailsTab.customer.change_modal.title,
        header: _vm.headerObject,
        "data-test-id": "customer_change-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-wrap mx-3" },
            [
              _c(
                "div",
                { staticClass: "w-100 mt-5" },
                [
                  _c("label", { staticClass: "emobg-caption-2 d-block mb-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$labels.InvoiceDetailsTab.customer.change_modal
                            .type
                        ) +
                        "* "
                    ),
                  ]),
                  _c("ui-radio", {
                    staticClass: "w-100",
                    attrs: {
                      value: false,
                      caption:
                        _vm.$labels.InvoiceDetailsTab.customer.change_modal
                          .user,
                      name: "user-radio",
                      "data-test-id": "user-radio_button",
                    },
                    on: { changevalue: () => (_vm.isCompany = false) },
                    model: {
                      value: _vm.isCompany,
                      callback: function ($$v) {
                        _vm.isCompany = $$v
                      },
                      expression: "isCompany",
                    },
                  }),
                  _c("ui-radio", {
                    staticClass: "w-100",
                    attrs: {
                      value: true,
                      caption:
                        _vm.$labels.InvoiceDetailsTab.customer.change_modal
                          .company,
                      name: "company-radio",
                      "data-test-id": "company-radio_button",
                    },
                    on: { changevalue: () => (_vm.isCompany = true) },
                    model: {
                      value: _vm.isCompany,
                      callback: function ($$v) {
                        _vm.isCompany = $$v
                      },
                      expression: "isCompany",
                    },
                  }),
                ],
                1
              ),
              _vm.isCompany
                ? _c("MuiAlgoliaSelect", {
                    staticClass: "col-12 mt-4 pl-0",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.companies,
                      title: (company) =>
                        `${company.id} - ${company.commercial_name}`,
                      label: `${_vm.$labels.InvoiceDetailsTab.customer.change_modal.company}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.customer.change_modal
                          .company_placeholder,
                      "path-value": "id",
                      name: "company",
                      "data-test-id": "company-select",
                    },
                    model: {
                      value: _vm.companyId,
                      callback: function ($$v) {
                        _vm.companyId = $$v
                      },
                      expression: "companyId",
                    },
                  })
                : _c("MuiAlgoliaSelect", {
                    staticClass: "col-12 mt-4 pl-0",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.users,
                      filters: `cs_operator_fk: ${_vm.activeOperatorId}`,
                      title: (user) =>
                        `${user.id} - ${user.first_name} ${user.last_name} - ${user.email}`,
                      label: `${_vm.$labels.InvoiceDetailsTab.customer.change_modal.user}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.customer.change_modal
                          .user_placeholder,
                      "path-value": "id",
                      name: "user",
                      "data-test-id": "user-select",
                    },
                    model: {
                      value: _vm.userId,
                      callback: function ($$v) {
                        _vm.userId = $$v
                      },
                      expression: "userId",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: !_vm.isSaveAllowed,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.onChangeCustomer },
            },
            [_vm._v(" " + _vm._s(_vm.$labels.Common.Actions.save) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }