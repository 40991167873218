<script>
import get from 'lodash/get';
import template from 'lodash/template';
import toNumber from 'lodash/toNumber';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiModal,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { DATE_FORMAT } from '@emobg/web-utils';
import moment from 'moment';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import { NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN } from '@/utils';

const COMPENSATION_PAYMENT_METHOD_ID = 3;

export default {
  name: 'InvoicePaymentsAddModal',

  components: {
    CancelButton,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiModal,
    MuiValidationWrapper,
  },

  directives: { Validate },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isFormValid: false,
      isOpen: false,
      paymentMethodId: undefined,
      amount: undefined,
      date: undefined,
      comments: undefined,
      minAmount: 0.01,
      maxAmount: 0,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoicePayments.STATUS.LOADING'),
      isError: state => get(state, 'invoicePayments.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData']),

    isAddAllowed() {
      return this.isFormValid && !!this.date;
    },
  },

  watch: {
    isModalOpen(newValue) {
      this.isOpen = newValue;

      if (newValue) {
        this.clearData();
        this.maxAmount = get(this, 'invoiceData.pendingAmount.value') / 100;
        this.date = moment();
      }
    },

    paymentMethodId() {
      this.minAmount = this.paymentMethodId === COMPENSATION_PAYMENT_METHOD_ID
        ? get(this, 'invoiceData.paidAmount.value') / -100
        : 0.01;
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN = NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN;

    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };

    this.maxAmount = get(this, 'invoiceData.pendingAmount.value') / 100;
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['postInvoicePayment']),

    template,

    async onAddPayment() {
      const payload = {
        amount: this.amount.toString(),
        date: this.date.format(DATE_FORMAT.date),
        description: this.comments,
        paymentMethodId: this.paymentMethodId,
      };

      await this.postInvoicePayment({
        invoiceId: get(this, 'invoiceData.id'),
        payload,
      });

      this.$emit('on:close');

      if (!this.isError) {
        this.$notify({
          message: this.$labels.InvoiceDetailsTab.payments.modal.add.successfully,
          textAction: '',
        });
        this.$emit('on:change');
      }
    },

    areAllValid(isValid) {
      this.isFormValid = isValid;
    },

    isAmountValid(value) {
      const currentAmount = toNumber(value);

      return this.minAmount <= currentAmount && currentAmount <= this.maxAmount;
    },

    clearData() {
      this.paymentMethodId = undefined;
      this.amount = undefined;
      this.date = undefined;
      this.comments = undefined;
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="$labels.InvoiceDetailsTab.payments.modal.add.title"
    :header="headerObject"
    class="InvoicePaymentsModal"
    data-test-id="payment-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <MuiValidationWrapper
        class="d-flex flex-wrap mx-3"
        @areAllValid="areAllValid"
      >
        <MuiAlgoliaSelect
          v-model="paymentMethodId"
          v-validate="{
            isRequired: true
          }"
          :index="ALGOLIA_INDEXES.paymentMethods"
          :title="method => method.name"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.payment_method}*`"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.payment_method_plaheholder"
          path-value="id"
          name="paymentMethod"
          class="col-6 my-4 pl-0"
          data-test-id="payment_method-select"
        />
        <ui-datetimepicker
          :date.prop="moment(date)"
          :size="SIZES.small"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.date}*`"
          skiptime
          class="col-6 my-4 pr-0"
          data-test-id="date-input"
          @datechanged="({ detail }) => date = detail"
        />
        <MuiInputText
          v-model="amount"
          v-validate.blur="{
            isRequired: true,
            isPattern: {
              pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
              message: $labels.InvoiceDetailsTab.payments.modal.amount_format_error,
            },
            isAmountOutOfRange: (value) => {
              const limits = { min: minAmount, max: maxAmount };

              return {
                isValid: isAmountValid(value),
                message: template($labels.InvoiceDetailsTab.payments.modal.amount_out_of_range)(limits),
              };
            },
          }"
          :type="INPUT_TYPES.number"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.amount}*`"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.amount_placeholder"
          name="amount"
          class="col-12 px-0"
          data-test-id="amount-input"
        />
        <ui-text-area
          :value="comments"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.comments_placeholder"
          :label="$labels.InvoiceDetailsTab.payments.modal.comments"
          class="d-block mb-4 w-100"
          name="comments"
          data-test-id="description-input"
          @changevalue="({ detail }) => comments = detail"
        />
      </MuiValidationWrapper>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ui-button
        :disabled="!isAddAllowed"
        data-test-id="add-button"
        @clickbutton="onAddPayment"
      >
        {{ $labels.InvoiceDetailsTab.payments.actions.add }}
      </ui-button>
    </div>
  </MuiModal>
</template>
