<script>
import { mapGetters } from 'vuex';
import { DATE_FORMAT, formatDate } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import InvoiceRefundableServiceLineComponent from '../InvoiceServiceLine/InvoiceRefundableServiceLineComponent';
import InvoiceServiceLineComponent from '../InvoiceServiceLine/InvoiceServiceLineComponent';

export default {
  name: 'InvoiceBookingComponent',

  components: {
    InvoiceServiceLineComponent,
    InvoiceRefundableServiceLineComponent,
  },

  inject: ['$labels'],

  props: {
    isEditAllowed: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      bookings: [],
    };
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, [
      'invoiceId',
      'refundableBookingData',
      'isDraftCreditNote',
      'bookingData',
      'refundableServiceData',
      'serviceData',
    ]),
  },

  created() {
    this.DATE_FORMAT = DATE_FORMAT;

    this.$watch(vm => [vm.isDraftCreditNote, vm.refundableBookingData, vm.bookingData],
      ([isDraftCreditNote, refundableBookingData, bookingData]) => {
        this.bookings = isDraftCreditNote ? refundableBookingData : bookingData;
      }, {
        immediate: true,
      });
  },

  methods: {
    formatDate,

    userFullName(user) {
      return `${user.firstName} ${user.lastName}`;
    },

    callBookingService(booking) {
      const draftServices = map(filter(this.refundableServiceData, { bookingId: booking }), service => {
        const draftService = find(this.serviceData, { linkedId: service.id });
        const isDraftService = !isEmpty(draftService);

        return {
          ...service,
          isToRefund: isDraftService,
          unitsToRefund: isDraftService ? draftService.units : service.inversedUnits,
          grossAmountStr: isDraftService ? draftService.grossAmount.valueStr : service.grossAmount.inversedValueStr,
        };
      });

      return draftServices;
    },
  },
};
</script>

<template>
  <div
    class="InvoiceBookingComponent d-flex flex-wrap"
    data-test-id="invoice_booking-component"
  >
    <div
      v-for="booking in bookings"
      :key="booking.bookingId"
      class="d-flex flex-wrap w-100 mt-2 mb-6 align-items-center"
    >
      <h5 class="mb-2 mr-2">
        {{ $labels.InvoiceDetailsTab.booking.title }}
      </h5>

      <a
        :href="`/carsharing/bookings/${booking.uuid}`"
        target="_blank"
        class="emobg-link-primary emobg-body-2 mb-2 mr-2"
        data-test-id="link"
      >
        {{ booking.id }}
        <ui-icon
          :icon="ICONS.externalUrl"
          :size="SIZES.small"
          class="ml-1"
        />
      </a>

      <div class="mb-2">
        - {{ formatDate(booking.startStr, DATE_FORMAT.defaultExtended) }}
        - {{ formatDate(booking.endStr, DATE_FORMAT.defaultExtended) }}
      </div>

      <div class="col-12 pl-0 mb-2">
        {{ booking.location }}
      </div>

      <div class="mb-2 mr-1">
        {{ userFullName(booking.user) }} -
      </div>

      <div class="mb-2">
        {{ booking.vehiclePlate }}
      </div>

      <div class="col-12 pl-0 mb-2">
        {{ booking.vehicle }}
      </div>

      <InvoiceRefundableServiceLineComponent
        v-if="isDraftCreditNote"
        :draft-services="callBookingService(booking.id)"
        class="w-100 mb-6"
        v-on="$listeners"
      />

      <InvoiceServiceLineComponent
        v-else
        :booking-id="booking.id"
        :invoice-id="invoiceId"
        :is-edit-allowed="isEditAllowed"
        class="w-100"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.InvoiceBookingComponent {
  min-height: 5em;
}
</style>
