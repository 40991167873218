var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.total
    ? _c(
        "div",
        {
          staticClass: "InvoiceTotalsComponent height--size-s d-table",
          attrs: { "data-test-id": "invoice_payments-view" },
        },
        [
          _c("h5", { staticClass: "height--size-m" }, [
            _vm._v(
              " " + _vm._s(_vm.$labels.InvoiceDetailsTab.totals.title) + " "
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "emobg-color-ink-light emobg-border-color-ink-lighter",
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$labels.InvoiceDetailsTab.totals.subtotal) +
                  " "
              ),
              _c("span", { staticClass: "float-right" }, [
                _vm._v(" " + _vm._s(_vm.subTotal) + " "),
              ]),
            ]
          ),
          _vm._l(_vm.taxesData, function (tax, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "emobg-color-ink-light emobg-border-top-1 emobg-border-color-ink-lighter",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(tax.name) +
                    " (" +
                    _vm._s(_vm.get(tax, "vat.valueStr")) +
                    ") "
                ),
                _c("span", { staticClass: "float-right" }, [
                  _vm._v(" " + _vm._s(_vm.get(tax, "amount.valueStr")) + " "),
                ]),
              ]
            )
          }),
          _c(
            "div",
            {
              staticClass:
                "emobg-font-weight-bold emobg-border-top-1 emobg-border-color-ink-lighter",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$labels.InvoiceDetailsTab.totals.total) + " "
              ),
              _c("span", { staticClass: "float-right" }, [
                _vm._v(" " + _vm._s(_vm.total) + " "),
              ]),
            ]
          ),
          !_vm.isDraftCreditNote && _vm.hasDiscount
            ? _c(
                "div",
                {
                  staticClass:
                    "emobg-font-weight-bold emobg-border-top-1 emobg-border-color-ink-lighter",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$labels.InvoiceDetailsTab.totals.total_to_pay_amount
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "float-right" }, [
                    _vm._v(" " + _vm._s(_vm.totalToPay) + " "),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isDraftCreditNote && !_vm.isCancellation
            ? _c(
                "div",
                {
                  staticClass:
                    "emobg-font-weight-bold emobg-border-top-1 emobg-border-color-ink-lighter",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$labels.InvoiceDetailsTab.totals.related_invoices
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isDraftCreditNote &&
          _vm.isPendingAmountVisible &&
          !_vm.isCancellation
            ? _c(
                "div",
                {
                  staticClass:
                    "emobg-color-ink-light emobg-border-color-ink-lighter",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$labels.InvoiceDetailsTab.totals.pending) +
                      " "
                  ),
                  _c("span", { staticClass: "float-right" }, [
                    _vm._v(" " + _vm._s(_vm.pending) + " "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.isInvoice
            ? _c("div", { staticClass: "emobg-font-weight-bold pt-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$labels.InvoiceDetailsTab.totals.total_credit_notes
                    ) +
                    " "
                ),
                _c("span", { staticClass: "float-right" }, [
                  _vm._v(" " + _vm._s(_vm.totalCreditNotes) + " "),
                ]),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }