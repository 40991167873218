<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';

import { MuiModal } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, ConfirmButton } from '@/components';

export default {
  name: 'InvoicePaymentsDeleteModal',

  components: {
    CancelButton,
    ConfirmButton,
    MuiModal,
  },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    paymentId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      isOpen: false,
      reference: null,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoicePayments.STATUS.LOADING'),
      isError: state => get(state, 'invoicePayments.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['getPaymentById']),
  },

  watch: {
    isModalOpen(isOpen) {
      this.isOpen = isOpen;
    },

    paymentId(newValue) {
      if (newValue) {
        this.reference = this.getPaymentById(this.paymentId).transactionRef;
      }
    },
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['deleteInvoicePayment']),

    async onDeletePayment() {
      await this.deleteInvoicePayment(this.paymentId);

      this.$emit('on:close');

      if (!this.isError) {
        this.showSuccessfullyAction(this.$labels.InvoiceDetailsTab.payments.modal.delete.successfully);
        this.$emit('on:change');
      }
    },

    showSuccessfullyAction(message) {
      this.$notify({
        message,
        textAction: '',
      });
    },
  },
};
</script>

<template>
  <MuiModal
    v-if="paymentId"
    v-model="isOpen"
    :size="SIZES.small"
    data-test-id="delete_payment-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <div class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large px-3 mt-5 mb-4">
        {{ $labels.InvoiceDetailsTab.payments.modal.delete.confirmation }}
      </div>

      <div class="emobg-font-default emobg-color-ink-light px-3 mb-5">
        {{ $labels.InvoiceDetailsTab.payments.reference }}: {{ reference }}
      </div>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ConfirmButton
        data-test-id="confirm-button"
        @click="onDeletePayment"
      />
    </div>
  </MuiModal>
</template>
