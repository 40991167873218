var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      attrs: {
        title: _vm.title,
        header: _vm.headerObject,
        "data-test-id": "service_line-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "MuiValidationWrapper",
            {
              staticClass: "d-flex flex-wrap",
              on: { areAllValid: _vm.areAllValid },
            },
            [
              !_vm.isAddMode
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "d-block mx-2 mb-4",
                      attrs: {
                        color: _vm.COLORS.warning,
                        icon: _vm.ICONS.alertFull,
                        "data-test-id": "notification",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.service_lines.modal
                              .attention_text
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isBookingSelectorAllowed
                ? _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          ...(_vm.isBookingRequired
                            ? { isRequired: true }
                            : ""),
                        },
                        expression:
                          "{\n          ...(isBookingRequired ? {isRequired: true} : '')\n        }",
                      },
                    ],
                    staticClass: "col-12 mb-4",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.csBookings,
                      filters: _vm.customerFilter,
                      title: (booking) => _vm.displayBookingSelector(booking),
                      label: `${
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .booking
                      }${_vm.isBookingRequired ? "*" : ""}`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .booking_placeholder,
                      "path-value": "id",
                      name: "booking",
                      "data-test-id": "booking-select",
                    },
                    model: {
                      value: _vm.bookingId,
                      callback: function ($$v) {
                        _vm.bookingId = $$v
                      },
                      expression: "bookingId",
                    },
                  })
                : _vm._e(),
              _c("MuiAlgoliaSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true\n        }",
                  },
                ],
                staticClass: "col-12 mb-4",
                attrs: {
                  index: _vm.ALGOLIA_INDEXES.services,
                  filters: `cs_operator_fk: ${_vm.activeOperatorId}`,
                  title: (service) =>
                    `${service.code} ${service.internal_name}`,
                  label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.service}*`,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.service_lines.modal
                      .service_placeholder,
                  "path-value": "id",
                  name: "service",
                  "data-test-id": "service-select",
                },
                model: {
                  value: _vm.serviceId,
                  callback: function ($$v) {
                    _vm.serviceId = $$v
                  },
                  expression: "serviceId",
                },
              }),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isPattern: {
                            pattern: _vm.POSITIVE_BUT_ZERO_PATTERN,
                            message: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.units_error}`,
                          },
                        },
                        expression:
                          "{\n            isRequired: true,\n            isPattern: {\n              pattern: POSITIVE_BUT_ZERO_PATTERN,\n              message: `${$labels.InvoiceDetailsTab.service_lines.modal.units_error}`,\n            },\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      type: _vm.INPUT_TYPES.number,
                      label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.units}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .units_placeholder,
                      name: "units",
                      "data-test-id": "units-input",
                    },
                    model: {
                      value: _vm.units,
                      callback: function ($$v) {
                        _vm.units = $$v
                      },
                      expression: "units",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isPattern: {
                            pattern: _vm.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
                            message: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.price_error}`,
                          },
                        },
                        expression:
                          "{\n            isRequired: true,\n            isPattern: {\n              pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,\n              message: `${$labels.InvoiceDetailsTab.service_lines.modal.price_error}`,\n            },\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      type: _vm.INPUT_TYPES.number,
                      label: `${_vm.$labels.InvoiceDetailsTab.service_lines.modal.price}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .price_placeholder,
                      name: "price",
                      "data-test-id": "price-input",
                    },
                    model: {
                      value: _vm.amount,
                      callback: function ($$v) {
                        _vm.amount = $$v
                      },
                      expression: "amount",
                    },
                  }),
                ],
                1
              ),
              !_vm.isAddMode
                ? _c("ui-text-area", {
                    staticClass: "d-block w-100 mt-4 mx-2",
                    attrs: {
                      value: _vm.description,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .comments_placeholder,
                      label:
                        _vm.$labels.InvoiceDetailsTab.service_lines.modal
                          .comments,
                      name: "description",
                      rows: "4",
                      "data-test-id": "description-input",
                    },
                    on: {
                      changevalue: ({ detail }) => (_vm.description = detail),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c("SaveButton", {
            attrs: {
              disabled: !_vm.isSaveAllowed,
              "data-test-id": "save-button",
            },
            on: { click: _vm.onSave },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }