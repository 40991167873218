<script>
import get from 'lodash/get';
import template from 'lodash/template';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { MuiModal } from '@emobg/motion-ui/v1';
import moment from 'moment';
import { CancelButton, ConfirmButton } from '@/components';

// Note:
//  `legalPeriodToCreateCreditNote` value should be a new `property: value` that comes from operator config
//  https://api.ph-dev.ubeeqo.com/backoffice/user-company/{{ version }}/operators/{{ operatorUuid }}/config
const legalPeriodToCreateCreditNote = 14;

export default {
  name: 'CreditNoteCreationConfirmationModal',

  components: {
    CancelButton,
    ConfirmButton,
    MuiModal,
  },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isOpen: false,
      title: undefined,
      text: undefined,
      notification: undefined,
    };
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData']),

    dueDate: ({ invoiceData }) => get(invoiceData, 'dueDate'),

    isWithinLegalPeriod() {
      return moment().isSameOrBefore(moment(this.dueDate).add(legalPeriodToCreateCreditNote, 'days'));
    },
  },

  watch: {
    isModalOpen(isOpen) {
      this.isOpen = isOpen;

      if (isOpen) {
        if (this.isWithinLegalPeriod) {
          this.setWithinLegalPeriodLabels();
        } else {
          this.setOutOfLegalPeriodLabels();
        }
      }
    },
  },

  methods: {
    onConfirmCreditNoteCreation() {
      this.$emit('on:close');
      this.$emit('on:create-credit-note-draft');
    },

    setWithinLegalPeriodLabels() {
      this.title = this.$labels.InvoiceDetailsTab.credit_note.create.within_legal_period.title;
      this.text = this.$labels.InvoiceDetailsTab.credit_note.create.within_legal_period.text;
    },

    setOutOfLegalPeriodLabels() {
      this.title = template(this.$labels.InvoiceDetailsTab.credit_note.create.out_of_legal_period.title)({ days: legalPeriodToCreateCreditNote });
      this.text = template(this.$labels.InvoiceDetailsTab.credit_note.create.out_of_legal_period.text)({ days: legalPeriodToCreateCreditNote });
      this.notification = template(this.$labels.InvoiceDetailsTab.credit_note.create.out_of_legal_period.notification)({ days: legalPeriodToCreateCreditNote });
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :size="SIZES.small"
    data-test-id="credit_note_confirmation-modal"
    @modal-closed="$emit('on:close')"
  >
    <template #body>
      <div class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large px-3 mt-5 mb-4">
        {{ title }}
      </div>

      <ui-alert
        v-if="!isWithinLegalPeriod"
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="emobg-font-line-height-large mx-3 mb-4"
      >
        {{ notification }}
      </ui-alert>

      <div class="emobg-font-default emobg-color-ink-light emobg-font-line-height-large px-3 mb-5">
        {{ text }}
      </div>
    </template>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ConfirmButton
        data-test-id="confirm-button"
        @click="onConfirmCreditNoteCreation"
      />
    </div>
  </MuiModal>
</template>
