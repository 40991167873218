<script>
import get from 'lodash/get';
import size from 'lodash/size';
import { mapActions, mapGetters, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import ServiceLineModal from './ServiceLineModal';

export default {
  name: 'InvoiceServiceLineComponent',

  components: {
    ServiceLineModal,
  },

  inject: ['$labels'],

  props: {
    isEditAllowed: {
      required: true,
      type: Boolean,
    },
    bookingId: {
      type: Number,
      default: null,
    },
    invoiceId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isModalOpen: false,
      serviceData: {},
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isError: state => get(state, 'serviceLine.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['getServicesByBookingId']),

    serviceLines() {
      return this.getServicesByBookingId(this.bookingId);
    },
  },

  methods: {
    get,
    size,
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['deleteInvoiceLine']),

    actions(service) {
      return [
        {
          label: this.$labels.InvoiceDetailsTab.service_lines.actions.edit,
          action: () => {
            this.serviceData = service;
            this.isModalOpen = true;
          },
        },
        {
          label: this.$labels.InvoiceDetailsTab.service_lines.actions.delete,
          labelClass: 'emobg-color-danger',
          action: () => this.onDeleteServiceLine(service.id),
        },
      ];
    },

    async onDeleteServiceLine(serviceId) {
      await this.deleteInvoiceLine({
        invoiceId: this.invoiceId,
        invoiceLineId: serviceId,
      });

      if (!this.isError) {
        this.$notify({
          message: this.$labels.InvoiceDetailsTab.service_lines.notifications.delete_successfully,
          textAction: '',
        });
        this.$emit('on:change');
      }
    },

    onCloseEditModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<template>
  <div
    v-if="size(serviceLines) || isEditAllowed"
    class="InvoiceServiceLineComponent"
    data-test-id="invoice_service_line-component"
  >
    <table class="w-100">
      <thead>
        <tr class="emobg-color-ink-light emobg-border-bottom-2 emobg-border-color-ground-light align-items-center d-flex py-3">
          <th class="text-left col-6 pl-0">
            {{ $labels.InvoiceDetailsTab.service_lines.service }}
          </th>

          <th class="text-right col-1">
            {{ $labels.InvoiceDetailsTab.service_lines.units }}
          </th>

          <th class="text-right col-2">
            {{ $labels.InvoiceDetailsTab.service_lines.price }}
          </th>

          <th class="text-right col-2">
            {{ $labels.InvoiceDetailsTab.service_lines.total }}
          </th>

          <th />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(serviceLine, index) in serviceLines"
          :key="index"
          class="emobg-caption-1 emobg-border-bottom-1 emobg-border-color-ink-lighter align-items-center d-flex"
        >
          <td class="col-6 pl-0">
            {{ serviceLine.name }}

            <div
              v-if="serviceLine.description"
              class="emobg-color-ink-light mt-1"
            >
              {{ serviceLine.description }}
            </div>
          </td>

          <td class="text-right col-1 py-3">
            {{ serviceLine.units }}
          </td>

          <td class="text-right col-2">
            {{ get(serviceLine, 'amount.valueStr') }}
          </td>

          <td class="text-right col-2">
            {{ get(serviceLine, 'grossAmount.valueStr') }}
          </td>

          <td class="col-1 pr-0">
            <ui-dropdown
              v-if="isEditAllowed"
              :data-test-id="`open-dropdown-${index}`"
              class="mt-2 float-right"
            >
              <ui-icon
                slot="trigger"
                :icon="ICONS.optionsHorizontalFull"
                :color="GRAYSCALE.inkLight"
                data-test-id="icon"
              />
              <ui-dropdown-actions
                slot="content"
                :actions.prop="actions(serviceLine)"
                data-test-id="dropdown-actions"
              />
            </ui-dropdown>
          </td>
        </tr>
      </tbody>
    </table>

    <ServiceLineModal
      :is-modal-open="isModalOpen"
      :title="$labels.InvoiceDetailsTab.service_lines.modal.edit_title"
      :service-data="serviceData"
      @on:close="onCloseEditModal"
      @on:change="$emit('on:change')"
    />
  </div>
</template>

<style lang="scss" scoped>
.InvoiceServiceLineComponent {
  min-height: 6em;
}
</style>
