var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.serieType
    ? _c("div", { attrs: { "data-test-id": "invoice_customer-component" } }, [
        _c("h5", { staticClass: "text-uppercase mb-2" }, [
          _vm._v(" " + _vm._s(_vm.serieType) + " "),
        ]),
        _c(
          "div",
          { staticClass: "emobg-font-default emobg-font-weight-bold mb-2" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.invoiceData.serie) +
                "-" +
                _vm._s(_vm.invoiceData.number) +
                " "
            ),
          ]
        ),
        _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
          _vm._v(" " + _vm._s(_vm.invoiceData.dateStr) + " "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }