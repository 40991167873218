<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';

import { MuiModal } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, ConfirmButton } from '@/components';

export default {
  name: 'InvoiceCloseModal',

  components: {
    CancelButton,
    ConfirmButton,
    MuiModal,
  },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'STATUS.LOADING'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData']),

    invoiceId: ({ invoiceData }) => get(invoiceData, 'id'),
    invoiceSerie: ({ invoiceData }) => get(invoiceData, 'serie'),
    invoiceNumber: ({ invoiceData }) => get(invoiceData, 'number'),
    invoiceDate: ({ invoiceData }) => get(invoiceData, 'dateStr'),
  },

  watch: {
    isModalOpen(isOpen) {
      this.isOpen = isOpen;
    },
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['putCloseInvoice']),

    async onInvoiceClose() {
      await this.putCloseInvoice(this.invoiceId);

      this.$emit('on:close');
      this.$emit('on:change');
    },
  },
};
</script>

<template>
  <MuiModal
    v-if="invoiceId"
    v-model="isOpen"
    :size="SIZES.small"
    data-test-id="invoice_close-modal"
    @modal-closed="$emit('on:close')"
  >
    <template #body>
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <div class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large px-3 mt-5 mb-4">
        {{ $labels.InvoiceDetailsTab.close.confirmation }}
      </div>

      <div class="emobg-font-default emobg-color-ink-light px-3 mb-5">
        {{ invoiceSerie }}-{{ invoiceNumber }} ({{ invoiceDate }})
      </div>
    </template>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ConfirmButton
        data-test-id="confirm-button"
        @click="onInvoiceClose"
      />
    </div>
  </MuiModal>
</template>
