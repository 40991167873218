var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.issuerData
    ? _c(
        "div",
        {
          staticClass: "InvoiceIssuerComponent",
          attrs: { "data-test-id": "invoice_issuer-component" },
        },
        [
          _c("img", {
            staticClass: "InvoiceIssuerComponent__logo mb-4",
            attrs: { src: _vm.issuerData.logoUrl, alt: "Issuer logo" },
          }),
          _c("h5", { staticClass: "emobg-font-default mb-2" }, [
            _vm._v(" " + _vm._s(_vm.issuerData.name) + " "),
          ]),
          _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
            _vm._v(" " + _vm._s(_vm.issuerData.fiscalNumber) + " "),
          ]),
          _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
            _vm._v(" " + _vm._s(_vm.issuerData.addressStreet) + " "),
          ]),
          _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
            _vm._v(" " + _vm._s(_vm.issuerData.addressCity) + " "),
          ]),
          _vm.issuerData.addressPhone
            ? _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$labels.InvoiceDetailsTab.issuer.phone_abbr) +
                    ": " +
                    _vm._s(_vm.issuerData.addressPhone) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.issuerData.registrationNumber
            ? _c("div", { staticClass: "emobg-color-ink-light mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$labels.InvoiceDetailsTab.issuer.vat_abbr) +
                    ": " +
                    _vm._s(_vm.issuerData.registrationNumber) +
                    " "
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }