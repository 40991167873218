var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "InvoiceRefundableServiceLineComponent",
      attrs: { "data-test-id": "invoice_refundable-service_line-component" },
    },
    [
      _c("table", { staticClass: "w-100" }, [
        _c("thead", [
          _c(
            "tr",
            {
              staticClass:
                "emobg-color-ink-light emobg-border-bottom-2 emobg-border-color-ground-light align-items-center d-flex py-3",
            },
            [
              _c("th", { staticClass: "col-1 pl-0" }),
              _c("th", { staticClass: "text-left col-5" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$labels.InvoiceDetailsTab.service_lines.service
                    ) +
                    " "
                ),
              ]),
              _c("th", { staticClass: "text-right col-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$labels.InvoiceDetailsTab.service_lines.units) +
                    " "
                ),
              ]),
              _c("th", { staticClass: "text-right col-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$labels.InvoiceDetailsTab.service_lines.price) +
                    " "
                ),
              ]),
              _c("th", { staticClass: "text-right col-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$labels.InvoiceDetailsTab.service_lines.total) +
                    " "
                ),
              ]),
            ]
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.draftServices, function (service, index) {
            return _c(
              "tr",
              {
                key: index,
                staticClass:
                  "emobg-caption-1 emobg-border-bottom-1 emobg-border-color-ink-lighter align-items-center d-flex",
              },
              [
                _c(
                  "td",
                  { staticClass: "col-1 pl-0" },
                  [
                    _c("ui-checkbox", {
                      attrs: {
                        checked: service.isToRefund,
                        "data-test-id": `refundable-service-checkbox-${index}`,
                        name: "is_to_refund",
                      },
                      on: {
                        changevalue: ({ detail }) => {
                          service.isToRefund = detail
                          _vm.requestForChangeInDraft(
                            service.id,
                            service.unitsToRefund,
                            detail
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  {
                    staticClass: "col-5 align-top",
                    class: { "emobg-color-ink-light": !service.refund },
                  },
                  [
                    _vm._v(" " + _vm._s(service.name) + " "),
                    service.description
                      ? _c(
                          "div",
                          { staticClass: "emobg-color-ink-light mt-1" },
                          [_vm._v(" " + _vm._s(service.description) + " ")]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "td",
                  { staticClass: "text-right col-2 py-3" },
                  [
                    _c("ui-select", {
                      attrs: {
                        disabled: !service.isToRefund,
                        "data-test-id": `units-select-${index}`,
                        name: "units",
                      },
                      domProps: {
                        value: service.unitsToRefund,
                        options: _vm.selectUnits(service.inversedUnits),
                      },
                      on: {
                        selectoption: ({ detail }) =>
                          _vm.requestForChangeInDraft(
                            service.id,
                            detail,
                            service.isToRefund
                          ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  {
                    staticClass: "text-right col-2",
                    class: { "emobg-color-ink-light": !service.refund },
                  },
                  [_vm._v(" " + _vm._s(service.amount.valueStr) + " ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "text-right col-2",
                    class: { "emobg-color-ink-light": !service.refund },
                  },
                  [_vm._v(" " + _vm._s(service.grossAmountStr) + " ")]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }