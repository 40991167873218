<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  MuiModal,
  MuiTextarea,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';

export default {
  name: 'InvoicePaymentsRetryModal',

  components: {
    CancelButton,
    MuiModal,
    MuiTextarea,
  },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    paymentId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      isOpen: false,
      isRetryAllowed: false,
      previousPaymentData: {},
      amount: undefined,
      description: undefined,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoicePayments.STATUS.LOADING'),
      isError: state => get(state, 'invoicePayments.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['getPaymentById', 'invoiceData']),

    pendingAmount: ({ invoiceData }) => get(invoiceData, 'pendingAmount.value') / 100,
    pendingAmountStr: ({ invoiceData }) => get(invoiceData, 'pendingAmount.valueStr'),
  },

  watch: {
    isModalOpen(newValue) {
      this.isOpen = newValue;
    },

    paymentId: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.getPaymentData();
        }
      },
    },
  },

  created() {
    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };
  },

  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['putRetryInvoicePayment']),

    getPaymentData() {
      this.previousPaymentData = this.getPaymentById(this.paymentId);

      this.amount = get(this, 'previousPaymentData.amount.value') / 100;

      this.isRetryAllowed = this.amount <= this.pendingAmount;
    },

    async onRetryPayment() {
      const payload = {
        amount: this.amount.toString(),
        description: this.description,
      };

      await this.putRetryInvoicePayment({
        paymentId: this.paymentId,
        payload,
      });

      this.$emit('on:close');

      if (!this.isError) {
        this.showSuccessfullyAction(this.$labels.InvoiceDetailsTab.payments.modal.retry.successfully);
        this.$emit('on:change');
      }
    },

    showSuccessfullyAction(message) {
      this.$notify({
        message,
        textAction: '',
      });
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="$labels.InvoiceDetailsTab.payments.modal.retry.title"
    :header="headerObject"
    class="InvoicePaymentsModal"
    data-test-id="payment-modal"
    @modal-closed="$emit('on:close')"
  >
    <div
      slot="body"
      class="d-flex flex-wrap py-3"
    >
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <div class="col-6 mt-4 pl-0 pr-2">
        <label class="emobg-caption-2 d-block mb-1">
          {{ $labels.InvoiceDetailsTab.payments.modal.payment_method }}
        </label>

        <label class="emobg-caption-1 d-block mt-1">
          {{ previousPaymentData.methodName }}
        </label>
      </div>

      <div class="col-6 mt-4 pl-2 pr-0">
        <label class="emobg-caption-2 d-block mb-1">
          {{ $labels.InvoiceDetailsTab.payments.modal.date }}
        </label>

        <label class="emobg-caption-1 d-block mt-1">
          {{ previousPaymentData.dateStr }}
        </label>
      </div>

      <div class="col-12 mt-4 pl-0 pr-2">
        <label class="emobg-caption-2 d-block mb-1">
          {{ $labels.InvoiceDetailsTab.payments.modal.amount }}
        </label>

        <label class="emobg-caption-1 d-block mt-1">
          {{ get(previousPaymentData, 'amount.valueStr') }}
        </label>

        <div
          v-if="!isRetryAllowed"
          class="emobg-font-x-small emobg-font-weight-normal emobg-color-danger mt-1"
        >
          {{ $labels.InvoiceDetailsTab.payments.modal.retry.pending_greater_than_amount }} {{ pendingAmountStr }}
        </div>
      </div>

      <MuiTextarea
        v-model="description"
        :placeholder="$labels.InvoiceDetailsTab.payments.modal.comments_placeholder"
        :label="$labels.InvoiceDetailsTab.payments.modal.comments"
        name="description"
        rows="4"
        class="col-12 mt-4 px-0"
        data-test-id="description-input"
      />
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ui-button
        :disabled="!isRetryAllowed"
        data-test-id="retry-button"
        @clickbutton="onRetryPayment"
      >
        {{ $labels.InvoiceDetailsTab.payments.modal.retry.action }}
      </ui-button>
    </div>
  </MuiModal>
</template>
