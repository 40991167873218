var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.size(_vm.serviceLines) || _vm.isEditAllowed
    ? _c(
        "div",
        {
          staticClass: "InvoiceServiceLineComponent",
          attrs: { "data-test-id": "invoice_service_line-component" },
        },
        [
          _c("table", { staticClass: "w-100" }, [
            _c("thead", [
              _c(
                "tr",
                {
                  staticClass:
                    "emobg-color-ink-light emobg-border-bottom-2 emobg-border-color-ground-light align-items-center d-flex py-3",
                },
                [
                  _c("th", { staticClass: "text-left col-6 pl-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$labels.InvoiceDetailsTab.service_lines.service
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", { staticClass: "text-right col-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$labels.InvoiceDetailsTab.service_lines.units
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", { staticClass: "text-right col-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$labels.InvoiceDetailsTab.service_lines.price
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", { staticClass: "text-right col-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$labels.InvoiceDetailsTab.service_lines.total
                        ) +
                        " "
                    ),
                  ]),
                  _c("th"),
                ]
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.serviceLines, function (serviceLine, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    staticClass:
                      "emobg-caption-1 emobg-border-bottom-1 emobg-border-color-ink-lighter align-items-center d-flex",
                  },
                  [
                    _c("td", { staticClass: "col-6 pl-0" }, [
                      _vm._v(" " + _vm._s(serviceLine.name) + " "),
                      serviceLine.description
                        ? _c(
                            "div",
                            { staticClass: "emobg-color-ink-light mt-1" },
                            [
                              _vm._v(
                                " " + _vm._s(serviceLine.description) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("td", { staticClass: "text-right col-1 py-3" }, [
                      _vm._v(" " + _vm._s(serviceLine.units) + " "),
                    ]),
                    _c("td", { staticClass: "text-right col-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.get(serviceLine, "amount.valueStr")) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-right col-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.get(serviceLine, "grossAmount.valueStr")) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "col-1 pr-0" },
                      [
                        _vm.isEditAllowed
                          ? _c(
                              "ui-dropdown",
                              {
                                staticClass: "mt-2 float-right",
                                attrs: {
                                  "data-test-id": `open-dropdown-${index}`,
                                },
                              },
                              [
                                _c("ui-icon", {
                                  attrs: {
                                    slot: "trigger",
                                    icon: _vm.ICONS.optionsHorizontalFull,
                                    color: _vm.GRAYSCALE.inkLight,
                                    "data-test-id": "icon",
                                  },
                                  slot: "trigger",
                                }),
                                _c("ui-dropdown-actions", {
                                  attrs: {
                                    slot: "content",
                                    "data-test-id": "dropdown-actions",
                                  },
                                  domProps: {
                                    actions: _vm.actions(serviceLine),
                                  },
                                  slot: "content",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("ServiceLineModal", {
            attrs: {
              "is-modal-open": _vm.isModalOpen,
              title:
                _vm.$labels.InvoiceDetailsTab.service_lines.modal.edit_title,
              "service-data": _vm.serviceData,
            },
            on: {
              "on:close": _vm.onCloseEditModal,
              "on:change": function ($event) {
                return _vm.$emit("on:change")
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }