var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      staticClass: "CreditNoteDraftCloseModal",
      attrs: {
        size: _vm.SIZES.large,
        "data-test-id": "credit_note_confirmation-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "body",
          fn: function () {
            return [
              _vm.isLoading
                ? _c("ui-loader", {
                    attrs: { fixed: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-xx-large emobg-font-weight-bold emobg-font-line-height-large px-3 mt-5 mb-3",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$labels.InvoiceDetailsTab.credit_note.summary.title
                      ) +
                      " "
                  ),
                ]
              ),
              !_vm.isWithinLegalPeriod
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "emobg-font-line-height-large mx-3 mb-4",
                      attrs: {
                        color: _vm.COLORS.warning,
                        icon: _vm.ICONS.alertFull,
                      },
                    },
                    [
                      _c("div", { staticClass: "w-100 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.advice) + " "),
                      ]),
                      _c("div", { staticClass: "w-100" }, [
                        _vm._v(" " + _vm._s(_vm.notification) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "px-3 mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$labels.InvoiceDetailsTab.credit_note.summary
                        .description
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-background-color-ground-lightest emobg-border-radius-large p-2 m-3 my-5",
                },
                [
                  _c("table", { staticClass: "w-100" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        {
                          staticClass:
                            "emobg-caption-2 emobg-color-ink d-flex py-2",
                        },
                        [
                          _c("th", { staticClass: "col-6 text-left pl-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$labels.InvoiceDetailsTab.credit_note
                                    .summary.header.service
                                ) +
                                " "
                            ),
                          ]),
                          _c("th", { staticClass: "col-1 text-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$labels.InvoiceDetailsTab.credit_note
                                    .summary.header.units
                                ) +
                                " "
                            ),
                          ]),
                          _c("th", { staticClass: "col-2 text-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$labels.InvoiceDetailsTab.credit_note
                                    .summary.header.amount
                                ) +
                                " "
                            ),
                          ]),
                          _c("th", { staticClass: "col-3 text-right pr-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$labels.InvoiceDetailsTab.credit_note
                                    .summary.header.total
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.serviceData, function (drafService, index) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            staticClass:
                              "emobg-caption-1 emobg-color-ink-light emobg-border-bottom-1 emobg-border-color-ink-lighter d-flex py-2",
                          },
                          [
                            _c("td", { staticClass: "col-6 pl-0" }, [
                              _vm._v(" " + _vm._s(drafService.name) + " "),
                            ]),
                            _c("td", { staticClass: "col-1 text-right" }, [
                              _vm._v(" " + _vm._s(drafService.units) + " "),
                            ]),
                            _c("td", { staticClass: "col-2 text-right" }, [
                              _vm._v(
                                " " + _vm._s(drafService.amount.valueStr) + " "
                              ),
                            ]),
                            _c("td", { staticClass: "col-3 text-right pr-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(drafService.grossAmount.valueStr) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "offset-6 col-6 pr-0" },
                    [
                      _c("div", { staticClass: "my-2" }, [
                        _c("span", { staticClass: "float-left" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$labels.InvoiceDetailsTab.credit_note
                                  .summary.totals.subtotal
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "float-right" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.invoiceData.subtotalAmount.valueStr) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm._l(_vm.taxesData, function (tax, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "emobg-border-top-1 emobg-border-color-ink-lighter pt-2",
                          },
                          [
                            _c("span", { staticClass: "float-left my-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(tax.name) +
                                  " (" +
                                  _vm._s(_vm.get(tax, "vat.valueStr")) +
                                  ") "
                              ),
                            ]),
                            _c("span", { staticClass: "float-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.get(tax, "amount.valueStr")) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "emobg-border-top-1 emobg-border-color-ink-lighter emobg-font-weight-semibold emobg-color-ink my-2 pt-2",
                        },
                        [
                          _c("span", { staticClass: "float-left" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$labels.InvoiceDetailsTab.credit_note
                                    .summary.totals.total
                                ) +
                                " "
                            ),
                          ]),
                          _c("span", { staticClass: "float-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.invoiceData.totalAmount.valueStr) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "ui-button",
            {
              attrs: {
                face: _vm.FACES.text,
                color: _vm.GRAYSCALE.inkLight,
                size: _vm.SIZES.large,
                "data-test-id": "review-button",
              },
              on: {
                clickbutton: function ($event) {
                  return _vm.$emit("on:close")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.credit_note.summary.actions
                      .review
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "ui-button",
            {
              attrs: {
                face: _vm.FACES.fill,
                color: _vm.COLORS.danger,
                size: _vm.SIZES.large,
                "data-test-id": "refund-button",
              },
              on: { clickbutton: _vm.onCloseDraftCreditNote },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.credit_note.summary.actions
                      .refund
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }