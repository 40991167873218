var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      staticClass: "InvoicePaymentsModal",
      attrs: {
        title: _vm.$labels.InvoiceDetailsTab.payments.modal.add.title,
        header: _vm.headerObject,
        "data-test-id": "payment-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "MuiValidationWrapper",
            {
              staticClass: "d-flex flex-wrap mx-3",
              on: { areAllValid: _vm.areAllValid },
            },
            [
              _c("MuiAlgoliaSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true\n        }",
                  },
                ],
                staticClass: "col-6 my-4 pl-0",
                attrs: {
                  index: _vm.ALGOLIA_INDEXES.paymentMethods,
                  title: (method) => method.name,
                  label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.payment_method}*`,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.payments.modal
                      .payment_method_plaheholder,
                  "path-value": "id",
                  name: "paymentMethod",
                  "data-test-id": "payment_method-select",
                },
                model: {
                  value: _vm.paymentMethodId,
                  callback: function ($$v) {
                    _vm.paymentMethodId = $$v
                  },
                  expression: "paymentMethodId",
                },
              }),
              _c("ui-datetimepicker", {
                staticClass: "col-6 my-4 pr-0",
                attrs: {
                  size: _vm.SIZES.small,
                  label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.date}*`,
                  skiptime: "",
                  "data-test-id": "date-input",
                },
                domProps: { date: _vm.moment(_vm.date) },
                on: { datechanged: ({ detail }) => (_vm.date = detail) },
              }),
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.blur",
                    value: {
                      isRequired: true,
                      isPattern: {
                        pattern: _vm.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
                        message:
                          _vm.$labels.InvoiceDetailsTab.payments.modal
                            .amount_format_error,
                      },
                      isAmountOutOfRange: (value) => {
                        const limits = {
                          min: _vm.minAmount,
                          max: _vm.maxAmount,
                        }

                        return {
                          isValid: _vm.isAmountValid(value),
                          message: _vm.template(
                            _vm.$labels.InvoiceDetailsTab.payments.modal
                              .amount_out_of_range
                          )(limits),
                        }
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isPattern: {\n            pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,\n            message: $labels.InvoiceDetailsTab.payments.modal.amount_format_error,\n          },\n          isAmountOutOfRange: (value) => {\n            const limits = { min: minAmount, max: maxAmount };\n\n            return {\n              isValid: isAmountValid(value),\n              message: template($labels.InvoiceDetailsTab.payments.modal.amount_out_of_range)(limits),\n            };\n          },\n        }",
                    modifiers: { blur: true },
                  },
                ],
                staticClass: "col-12 px-0",
                attrs: {
                  type: _vm.INPUT_TYPES.number,
                  label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.amount}*`,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.payments.modal
                      .amount_placeholder,
                  name: "amount",
                  "data-test-id": "amount-input",
                },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
              _c("ui-text-area", {
                staticClass: "d-block mb-4 w-100",
                attrs: {
                  value: _vm.comments,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.payments.modal
                      .comments_placeholder,
                  label: _vm.$labels.InvoiceDetailsTab.payments.modal.comments,
                  name: "comments",
                  "data-test-id": "description-input",
                },
                on: { changevalue: ({ detail }) => (_vm.comments = detail) },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: !_vm.isAddAllowed,
                "data-test-id": "add-button",
              },
              on: { clickbutton: _vm.onAddPayment },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$labels.InvoiceDetailsTab.payments.actions.add) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }