var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "InvoiceActionsComponent position-relative",
      attrs: { "data-test-id": "invoice_actions-component" },
    },
    [
      _c(
        "ui-button",
        {
          staticClass: "m-1",
          attrs: {
            face: _vm.FACES.outline,
            color: _vm.GRAYSCALE.inkLight,
            square: "",
            "data-test-id": "reload-button",
          },
          on: {
            clickbutton: function ($event) {
              return _vm.$emit("on:change")
            },
          },
        },
        [
          _c("ui-icon", {
            attrs: {
              color: _vm.COLORS.inkLight,
              icon: _vm.ICONS.refresh,
              size: _vm.ICONS_SIZES.large,
            },
          }),
        ],
        1
      ),
      _vm.isChangeCustomerAllowed
        ? _c(
            "ui-button",
            {
              staticClass: "m-1",
              attrs: {
                face: _vm.FACES.outline,
                "data-test-id": "change_customer-button",
              },
              on: {
                clickbutton: function ($event) {
                  return _vm.$emit("on:change-customer")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.actions.change_customer
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.isPdfAllowed
        ? _c(
            "ui-button",
            {
              staticClass: "m-1",
              attrs: {
                face: _vm.FACES.outline,
                color: _vm.GRAYSCALE.inkLight,
                square: "",
                "data-test-id": "pdf-button",
              },
              on: {
                clickbutton: function ($event) {
                  return _vm.$emit("on:pdf")
                },
              },
            },
            [
              _c("ui-icon", {
                attrs: {
                  color: _vm.COLORS.inkLight,
                  icon: _vm.ICONS.filePdf,
                  size: _vm.ICONS_SIZES.large,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isCreateCreditNoteAllowed
        ? _c(
            "ui-button",
            {
              staticClass: "m-1",
              attrs: {
                color: _vm.COLORS.danger,
                "data-test-id": "credit_note-button",
              },
              on: {
                clickbutton: function ($event) {
                  return _vm.$emit("on:create-credit-note")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.actions.create_credit_note
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.isValidStatus
        ? _c(
            "div",
            {
              class: [
                "InvoiceActionsComponent__status position-absolute emobg-color-white text-uppercase align-center",
                `${_vm.statusClass}`,
              ],
              attrs: {
                "data-status": _vm.invoiceStatus,
                "data-test-id": "close-button",
              },
              on: _vm._d({}, [
                _vm.closeInvoiceEvent,
                function ($event) {
                  return _vm.$emit("on:close")
                },
              ]),
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$labels.InvoiceDetailsTab.actions.status[
                      _vm.invoiceStatus
                    ]
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }