var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.size(_vm.paymentsData) || _vm.isAddPaymentAllowed
    ? _c(
        "div",
        {
          staticClass: "InvoicePaymentsComponent flex-wrap",
          attrs: { "data-test-id": "invoice_payments-component" },
        },
        [
          _c("h5", { staticClass: "height--size-m d-inline-block" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$labels.InvoiceDetailsTab.payments.payments) +
                " "
            ),
          ]),
          _vm.isAddPaymentAllowed
            ? _c(
                "ui-button",
                {
                  staticClass: "float-right mb-2",
                  attrs: {
                    face: _vm.FACES.outline,
                    "data-test-id": "add-button",
                  },
                  on: { clickbutton: _vm.onAddPayment },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$labels.InvoiceDetailsTab.payments.add_payment
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c("table", { staticClass: "w-100" }, [
            _c("thead", [
              _c(
                "tr",
                {
                  staticClass:
                    "emobg-color-ink-light emobg-border-bottom-2 emobg-border-color-ground-light align-items-center d-flex pt-3 pb-1",
                },
                [
                  _c(
                    "th",
                    {
                      staticClass: "col-2 mr-2 pl-0 text-left",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$labels.InvoiceDetailsTab.payments.date) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "w-20 mr-2 text-left",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.payments.method
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "w-10 mr-2 text-right",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.payments.amount
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "w-10 mr-2 text-left",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.payments.provider
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "w-20 mr-2 text-left",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.payments.reference
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("th", { staticClass: "w-10", attrs: { scope: "col" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$labels.InvoiceDetailsTab.payments.status) +
                        " "
                    ),
                  ]),
                  _c(
                    "th",
                    {
                      staticClass: "w-10 ml-2 text-left",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$labels.InvoiceDetailsTab.payments.comment
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("th", {
                    staticClass: "size-medium square",
                    attrs: { scope: "col" },
                  }),
                ]
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.paymentsData, function (payment) {
                return _c(
                  "tr",
                  {
                    key: payment.id,
                    staticClass:
                      "emobg-caption-1 emobg-border-bottom-1 emobg-border-color-ink-lighter align-items-center d-flex",
                  },
                  [
                    _c("td", { staticClass: "col-2 py-3 mr-2 pl-0" }, [
                      _vm._v(" " + _vm._s(payment.dateStr) + " "),
                    ]),
                    _c("td", { staticClass: "w-20 mr-2" }, [
                      _vm._v(" " + _vm._s(payment.methodName) + " "),
                    ]),
                    _c("td", { staticClass: "w-10 mr-2 text-right" }, [
                      _vm._v(
                        " " + _vm._s(_vm.get(payment, "amount.valueStr")) + " "
                      ),
                    ]),
                    _c("td", { staticClass: "w-10 mr-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            payment.provider || _vm.FALLBACK_MESSAGE.dash
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "w-20 mr-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            payment.transactionRef || _vm.FALLBACK_MESSAGE.dash
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      {
                        staticClass:
                          "w-10 mr-2 emobg-border-1 emobg-border-radius-pill text-center text-capitalize",
                        class: _vm.statusStyle(payment.status),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              payment.status || _vm.FALLBACK_MESSAGE.dash
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      { staticClass: "w-10 overflow-hidden" },
                      [
                        payment.description
                          ? _c(
                              "ui-tooltip",
                              {
                                staticClass: "align-middle",
                                attrs: {
                                  tooltip: payment.description,
                                  placement: _vm.PLACEMENTS.topEnd,
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "InvoicePaymentsComponent__comment ellipsis",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(payment.description) + " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _vm.areActionsAllowed(payment)
                          ? _c(
                              "ui-dropdown",
                              {
                                staticClass: "mt-2",
                                attrs: { placement: _vm.PLACEMENTS.left },
                              },
                              [
                                _c("ui-icon", {
                                  attrs: {
                                    slot: "trigger",
                                    icon: _vm.ICONS.optionsHorizontalFull,
                                    color: _vm.GRAYSCALE.inkLight,
                                  },
                                  slot: "trigger",
                                }),
                                _c("ui-dropdown-actions", {
                                  attrs: {
                                    slot: "content",
                                    "data-test-id": "dropdown-actions",
                                  },
                                  domProps: { actions: _vm.actions(payment) },
                                  slot: "content",
                                }),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "size-medium square" }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "InvoicePaymentsAddModal",
            _vm._g(
              {
                attrs: { "is-modal-open": _vm.isAddModalOpen },
                on: { "on:close": _vm.onCloseAddModal },
              },
              _vm.$listeners
            )
          ),
          _c(
            "InvoicePaymentsEditModal",
            _vm._g(
              {
                attrs: {
                  "is-modal-open": _vm.isEditModalOpen,
                  "payment-id": _vm.paymentId,
                },
                on: { "on:close": _vm.onCloseEditModal },
              },
              _vm.$listeners
            )
          ),
          _c(
            "InvoicePaymentsDeleteModal",
            _vm._g(
              {
                attrs: {
                  "is-modal-open": _vm.isDeleteModalOpen,
                  "payment-id": _vm.paymentId,
                },
                on: { "on:close": _vm.onCloseDeleteModal },
              },
              _vm.$listeners
            )
          ),
          _c(
            "InvoicePaymentsRetryModal",
            _vm._g(
              {
                attrs: {
                  "is-modal-open": _vm.isRetryModalOpen,
                  "payment-id": _vm.paymentId,
                },
                on: { "on:close": _vm.onCloseRetryModal },
              },
              _vm.$listeners
            )
          ),
          _c(
            "InvoicePaymentsRefundModal",
            _vm._g(
              {
                attrs: {
                  "is-modal-open": _vm.isRefundModalOpen,
                  "payment-id": _vm.paymentId,
                  "payment-transaction-uuid": _vm.paymentTransactionUuid,
                },
                on: { "on:close": _vm.onCloseRefundModal },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }