<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { INVOICE_TYPES } from '@domains/Invoicing/const/INVOICE_TYPES';

export default {
  name: 'InvoiceTotalsComponent',

  inject: ['$labels'],

  computed: {
    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'taxesData', 'isDraftCreditNote', 'invoiceType']),

    isPendingAmountVisible: ({ invoiceData }) => get(invoiceData, 'showPendingAmount'),
    subTotal: ({ invoiceData }) => get(invoiceData, 'subtotalAmount.valueStr'),
    totalToPay: ({ invoiceData }) => get(invoiceData, 'totalToPayAmount.valueStr'),
    pending: ({ invoiceData }) => get(invoiceData, 'pendingAmount.valueStr'),
    hasDiscount: ({ invoiceData }) => get(invoiceData, 'discountAmount.value') > 0,
    total: ({ invoiceData }) => get(invoiceData, 'totalAmount.valueStr'),
    totalCreditNotes: ({ invoiceData }) => get(invoiceData, 'totalClAmount.valueStr'),

    isInvoice: ({ invoiceType }) => invoiceType === INVOICE_TYPES.invoice,
    isCancellation: ({ invoiceType }) => invoiceType === INVOICE_TYPES.cancellation,
  },

  methods: {
    get,
  },
};
</script>

<template>
  <div
    v-if="total"
    class="InvoiceTotalsComponent height--size-s d-table"
    data-test-id="invoice_payments-view"
  >
    <h5 class="height--size-m">
      {{ $labels.InvoiceDetailsTab.totals.title }}
    </h5>

    <div class="emobg-color-ink-light emobg-border-color-ink-lighter">
      {{ $labels.InvoiceDetailsTab.totals.subtotal }}
      <span class="float-right">
        {{ subTotal }}
      </span>
    </div>

    <div
      v-for="(tax, index) in taxesData"
      :key="index"
      class="emobg-color-ink-light emobg-border-top-1 emobg-border-color-ink-lighter"
    >
      {{ tax.name }} ({{ get(tax, 'vat.valueStr') }})
      <span class="float-right">
        {{ get(tax, 'amount.valueStr') }}
      </span>
    </div>

    <div class="emobg-font-weight-bold emobg-border-top-1 emobg-border-color-ink-lighter">
      {{ $labels.InvoiceDetailsTab.totals.total }}
      <span class="float-right">
        {{ total }}
      </span>
    </div>

    <div
      v-if="!isDraftCreditNote && hasDiscount"
      class="emobg-font-weight-bold emobg-border-top-1 emobg-border-color-ink-lighter"
    >
      {{ $labels.InvoiceDetailsTab.totals.total_to_pay_amount }}
      <span class="float-right">
        {{ totalToPay }}
      </span>
    </div>

    <div
      v-if="!isDraftCreditNote && !isCancellation"
      class="emobg-font-weight-bold emobg-border-top-1 emobg-border-color-ink-lighter"
    >
      {{ $labels.InvoiceDetailsTab.totals.related_invoices }}
    </div>

    <div
      v-if="!isDraftCreditNote && isPendingAmountVisible && !isCancellation"
      class="emobg-color-ink-light emobg-border-color-ink-lighter"
    >
      {{ $labels.InvoiceDetailsTab.totals.pending }}
      <span class="float-right">
        {{ pending }}
      </span>
    </div>

    <div
      v-if="isInvoice"
      class="emobg-font-weight-bold pt-4"
    >
      {{ $labels.InvoiceDetailsTab.totals.total_credit_notes }}
      <span class="float-right">
        {{ totalCreditNotes }}
      </span>
    </div>
  </div>
</template>
