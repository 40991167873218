<script>
import get from 'lodash/get';
import template from 'lodash/template';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import moment from 'moment';
import { MuiModal } from '@emobg/motion-ui/v1';

// Note:
//  `legalPeriodToCreateCreditNote` value should be a new `property: value` that comes from operator config
//  https://api.ph-dev.ubeeqo.com/backoffice/user-company/{{ version }}/operators/{{ operatorUuid }}/config
const legalPeriodToCreateCreditNote = 14;

export default {
  name: 'CreditNoteDraftCloseModal',

  components: {
    MuiModal,
  },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isOpen: false,
      notification: undefined,
      advice: undefined,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'creditNote.STATUS.LOADING'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'taxesData', 'serviceData']),

    dueDate: ({ invoiceData }) => get(invoiceData, 'linkedInvoice.dueDate'),

    isWithinLegalPeriod() {
      return moment().isSameOrBefore(moment(this.dueDate).add(legalPeriodToCreateCreditNote, 'days'));
    },
  },

  watch: {
    isModalOpen(isOpen) {
      this.isOpen = isOpen;
    },
  },

  created() {
    this.notification = template(this.$labels.InvoiceDetailsTab.credit_note.create.out_of_legal_period.notification)({ days: legalPeriodToCreateCreditNote });
    this.advice = template(this.$labels.InvoiceDetailsTab.credit_note.create.out_of_legal_period.advice)({ days: legalPeriodToCreateCreditNote });
  },

  methods: {
    get,

    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['postDraftCreditNote']),

    async onCloseDraftCreditNote() {
      await this.postDraftCreditNote({
        invoiceUuid: this.invoiceData.uuid,
      });

      this.$emit('on:close');
      this.$emit('on:change');
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :size="SIZES.large"
    class="CreditNoteDraftCloseModal"
    data-test-id="credit_note_confirmation-modal"
    @modal-closed="$emit('on:close')"
  >
    <template #body>
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <div class="emobg-font-xx-large emobg-font-weight-bold emobg-font-line-height-large px-3 mt-5 mb-3">
        {{ $labels.InvoiceDetailsTab.credit_note.summary.title }}
      </div>

      <ui-alert
        v-if="!isWithinLegalPeriod"
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="emobg-font-line-height-large mx-3 mb-4"
      >
        <div class="w-100 mb-2">
          {{ advice }}
        </div>
        <div class="w-100">
          {{ notification }}
        </div>
      </ui-alert>

      <div class="px-3 mb-4">
        {{ $labels.InvoiceDetailsTab.credit_note.summary.description }}
      </div>

      <div class="emobg-background-color-ground-lightest emobg-border-radius-large p-2 m-3 my-5">
        <table class="w-100">
          <thead>
            <tr class="emobg-caption-2 emobg-color-ink d-flex py-2">
              <th class="col-6 text-left pl-0">
                {{ $labels.InvoiceDetailsTab.credit_note.summary.header.service }}
              </th>

              <th class="col-1 text-right">
                {{ $labels.InvoiceDetailsTab.credit_note.summary.header.units }}
              </th>

              <th class="col-2 text-right">
                {{ $labels.InvoiceDetailsTab.credit_note.summary.header.amount }}
              </th>

              <th class="col-3 text-right pr-0">
                {{ $labels.InvoiceDetailsTab.credit_note.summary.header.total }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(drafService, index) in serviceData"
              :key="index"
              class="emobg-caption-1 emobg-color-ink-light emobg-border-bottom-1 emobg-border-color-ink-lighter d-flex py-2"
            >
              <td class="col-6 pl-0">
                {{ drafService.name }}
              </td>

              <td class="col-1 text-right">
                {{ drafService.units }}
              </td>

              <td class="col-2 text-right">
                {{ drafService.amount.valueStr }}
              </td>

              <td class="col-3 text-right pr-0">
                {{ drafService.grossAmount.valueStr }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="offset-6 col-6 pr-0">
          <div class="my-2">
            <span class="float-left">
              {{ $labels.InvoiceDetailsTab.credit_note.summary.totals.subtotal }}
            </span>
            <span class="float-right">
              {{ invoiceData.subtotalAmount.valueStr }}
            </span>
          </div>

          <div
            v-for="(tax, index) in taxesData"
            :key="index"
            class="emobg-border-top-1 emobg-border-color-ink-lighter pt-2"
          >
            <span class="float-left my-2">
              {{ tax.name }} ({{ get(tax, 'vat.valueStr') }})
            </span>
            <span class="float-right">
              {{ get(tax, 'amount.valueStr') }}
            </span>
          </div>

          <div class="emobg-border-top-1 emobg-border-color-ink-lighter emobg-font-weight-semibold emobg-color-ink my-2 pt-2">
            <span class="float-left">
              {{ $labels.InvoiceDetailsTab.credit_note.summary.totals.total }}
            </span>
            <span class="float-right">
              {{ invoiceData.totalAmount.valueStr }}
            </span>
          </div>
        </div>
      </div>
    </template>

    <div
      slot="footer"
      class="d-flex justify-content-center p-3"
    >
      <ui-button
        :face="FACES.text"
        :color="GRAYSCALE.inkLight"
        :size="SIZES.large"
        data-test-id="review-button"
        @clickbutton="$emit('on:close')"
      >
        {{ $labels.InvoiceDetailsTab.credit_note.summary.actions.review }}
      </ui-button>

      <ui-button
        :face="FACES.fill"
        :color="COLORS.danger"
        :size="SIZES.large"
        data-test-id="refund-button"
        @clickbutton="onCloseDraftCreditNote"
      >
        {{ $labels.InvoiceDetailsTab.credit_note.summary.actions.refund }}
      </ui-button>
    </div>
  </MuiModal>
</template>
