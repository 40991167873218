var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.paymentId
    ? _c(
        "MuiModal",
        {
          attrs: {
            size: _vm.SIZES.small,
            "data-test-id": "delete_payment-modal",
          },
          on: {
            "modal-closed": function ($event) {
              return _vm.$emit("on:close")
            },
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm.isLoading
                ? _c("ui-loader", {
                    attrs: { fixed: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large px-3 mt-5 mb-4",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$labels.InvoiceDetailsTab.payments.modal.delete
                          .confirmation
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-default emobg-color-ink-light px-3 mb-5",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$labels.InvoiceDetailsTab.payments.reference) +
                      ": " +
                      _vm._s(_vm.reference) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("CancelButton", {
                attrs: { "data-test-id": "cancel-button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("on:close")
                  },
                },
              }),
              _c("ConfirmButton", {
                attrs: { "data-test-id": "confirm-button" },
                on: { click: _vm.onDeletePayment },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }