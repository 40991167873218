<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  MuiAlgoliaSelect,
  MuiModal,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'InvoiceChangeCustomerModal',

  components: {
    MuiAlgoliaSelect,
    CancelButton,
    MuiModal,
  },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isOpen: false,
      isSaveAllowed: false,
      isCompany: false,
      userId: undefined,
      companyId: undefined,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),

    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'STATUS.LOADING'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData']),

    invoiceId: ({ invoiceData }) => get(invoiceData, 'id'),
  },

  watch: {
    isModalOpen(newValue) {
      this.isOpen = newValue;

      if (newValue) {
        this.clearData();
      }
    },

    isCompany() {
      this.companyId = undefined;
      this.userId = undefined;
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;

    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };

    this.$watch(vm => [vm.isCompany, vm.companyId, vm.userId], ([isCompany, companyId, userId]) => {
      this.isSaveAllowed = isCompany ? companyId : userId;
    });
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['putCustomerInvoice']),

    async onChangeCustomer() {
      const payload = {
        userFk: this.userId,
        companyFk: this.companyId,
      };

      await this.putCustomerInvoice({
        invoiceId: this.invoiceId,
        payload,
      });

      this.$emit('on:close');
      this.$emit('on:change');
    },

    clearData() {
      this.isCompany = false;
      this.userId = undefined;
      this.companyId = undefined;
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="$labels.InvoiceDetailsTab.customer.change_modal.title"
    :header="headerObject"
    data-test-id="customer_change-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <div class="d-flex flex-wrap mx-3">
        <div class="w-100 mt-5">
          <label class="emobg-caption-2 d-block mb-1">
            {{ $labels.InvoiceDetailsTab.customer.change_modal.type }}*
          </label>

          <ui-radio
            v-model="isCompany"
            :value="false"
            :caption="$labels.InvoiceDetailsTab.customer.change_modal.user"
            name="user-radio"
            class="w-100"
            data-test-id="user-radio_button"
            @changevalue="() => isCompany = false"
          />

          <ui-radio
            v-model="isCompany"
            :value="true"
            :caption="$labels.InvoiceDetailsTab.customer.change_modal.company"
            name="company-radio"
            class="w-100"
            data-test-id="company-radio_button"
            @changevalue="() => isCompany = true"
          />
        </div>

        <MuiAlgoliaSelect
          v-if="isCompany"
          v-model="companyId"
          :index="ALGOLIA_INDEXES.companies"
          :title="company => `${company.id} - ${company.commercial_name}`"
          :label="`${$labels.InvoiceDetailsTab.customer.change_modal.company}*`"
          :placeholder="$labels.InvoiceDetailsTab.customer.change_modal.company_placeholder"
          path-value="id"
          name="company"
          class="col-12 mt-4 pl-0"
          data-test-id="company-select"
        />

        <MuiAlgoliaSelect
          v-else
          v-model="userId"
          :index="ALGOLIA_INDEXES.users"
          :filters="`cs_operator_fk: ${activeOperatorId}`"
          :title="user => `${user.id} - ${user.first_name} ${user.last_name} - ${user.email}`"
          :label="`${$labels.InvoiceDetailsTab.customer.change_modal.user}*`"
          :placeholder="$labels.InvoiceDetailsTab.customer.change_modal.user_placeholder"
          path-value="id"
          name="user"
          class="col-12 mt-4 pl-0"
          data-test-id="user-select"
        />
      </div>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ui-button
        :disabled="!isSaveAllowed"
        data-test-id="save-button"
        @clickbutton="onChangeCustomer"
      >
        {{ $labels.Common.Actions.save }}
      </ui-button>
    </div>
  </MuiModal>
</template>
