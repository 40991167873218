var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      attrs: {
        size: _vm.SIZES.small,
        "data-test-id": "credit_note_confirmation-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "body",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large px-3 mt-5 mb-4",
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              !_vm.isWithinLegalPeriod
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "emobg-font-line-height-large mx-3 mb-4",
                      attrs: {
                        color: _vm.COLORS.warning,
                        icon: _vm.ICONS.alertFull,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.notification) + " ")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-default emobg-color-ink-light emobg-font-line-height-large px-3 mb-5",
                },
                [_vm._v(" " + _vm._s(_vm.text) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c("ConfirmButton", {
            attrs: { "data-test-id": "confirm-button" },
            on: { click: _vm.onConfirmCreditNoteCreation },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }