<script>
import get from 'lodash/get';
import round from 'lodash/round';
import template from 'lodash/template';
import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  MuiInputText,
  MuiModal,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton } from '@/components';

import { NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN } from '@/utils';

export default {
  name: 'InvoicePaymentsEditModal',

  components: {
    CancelButton,
    MuiInputText,
    MuiModal,
    MuiValidationWrapper,
  },

  directives: { Validate },

  inject: ['$labels'],

  props: {
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    paymentId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      isOpen: false,
      isFormValid: false,
      isSaveAllowed: false,
      previousPaymentData: {},
      amount: undefined,
      originalAmount: undefined,
      minAmount: 0.01,
      maxAmount: 0,
      description: undefined,
      originalDescription: undefined,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoicePayments.STATUS.LOADING'),
      isError: state => get(state, 'invoicePayments.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['getPaymentById', 'invoiceData']),

    pendingAmount: ({ invoiceData }) => get(invoiceData, 'pendingAmount.value') / 100,
  },

  watch: {
    isModalOpen(newValue) {
      this.isOpen = newValue;

      if (newValue) {
        this.clearData();
      }
    },

    paymentId(newValue) {
      if (newValue) {
        this.getPaymentData();
      }
    },
  },

  created() {
    this.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN = NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN;

    this.headerObject = {
      isClosable: true,
      class: 'pl-3',
    };

    this.$watch(vm => [vm.isFormValid, vm.amount, vm.description], ([isFormValid, amount, description]) => {
      this.isSaveAllowed = isFormValid
        && (amount !== this.originalAmount || description !== this.originalDescription);
    });
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['putInvoicePayment']),

    template,

    getPaymentData() {
      this.previousPaymentData = this.getPaymentById(this.paymentId);

      this.amount = get(this, 'previousPaymentData.amount.value') / 100;
      this.originalAmount = this.amount;

      this.maxAmount = round(this.pendingAmount + this.amount, 2);

      this.description = toString(this.previousPaymentData.description);
      this.originalDescription = this.description;
    },

    async onEditPayment() {
      const payload = {
        amount: this.amount.toString(),
        description: this.description,
      };

      await this.putInvoicePayment({
        paymentId: this.paymentId,
        payload,
      });

      this.$emit('on:close');

      if (!this.isError) {
        this.showSuccessfullyAction(this.$labels.InvoiceDetailsTab.payments.modal.edit.successfully);
        this.$emit('on:change');
      }
    },

    showSuccessfullyAction(message) {
      this.$notify({
        message,
        textAction: '',
      });
    },

    areAllValid(isValid) {
      this.isFormValid = isValid;
    },

    isAmountValid(value) {
      const currentAmount = toNumber(value);

      return this.minAmount <= currentAmount && currentAmount <= this.maxAmount;
    },

    clearData() {
      this.amount = undefined;
      this.description = undefined;
    },
  },
};
</script>

<template>
  <MuiModal
    v-model="isOpen"
    :title="$labels.InvoiceDetailsTab.payments.modal.edit.title"
    :header="headerObject"
    class="InvoicePaymentsModal"
    data-test-id="payment-modal"
    @modal-closed="$emit('on:close')"
  >
    <div slot="body">
      <ui-loader
        v-if="isLoading"
        fixed
        data-test-id="loader"
      />

      <MuiValidationWrapper
        class="d-flex flex-wrap mx-3"
        @areAllValid="areAllValid"
      >
        <div class="col-6 my-4 pl-0 pr-2">
          <label class="emobg-caption-2 d-block mb-1">
            {{ $labels.InvoiceDetailsTab.payments.modal.payment_method }}
          </label>

          <label class="emobg-caption-1 d-block mt-1">
            {{ previousPaymentData.methodName }}
          </label>
        </div>

        <div class="col-6 my-4 pl-2 pr-0">
          <label class="emobg-caption-2 d-block mb-1">
            {{ $labels.InvoiceDetailsTab.payments.modal.date }}
          </label>

          <label class="emobg-caption-1 d-block mt-1">
            {{ previousPaymentData.dateStr }}
          </label>
        </div>

        <MuiInputText
          v-model="amount"
          v-validate.blur="{
            isRequired: true,
            isPattern: {
              pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
              message: $labels.InvoiceDetailsTab.payments.modal.amount_format_error,
            },
            isAmountOutOfRange: (value) => {
              const limits = { min: minAmount, max: maxAmount };

              return {
                isValid: isAmountValid(value),
                message: template($labels.InvoiceDetailsTab.payments.modal.amount_out_of_range)(limits),
              };
            },
          }"
          :type="INPUT_TYPES.number"
          :label="`${$labels.InvoiceDetailsTab.payments.modal.amount}*`"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.amount_placeholder"
          name="amount"
          class="col-12 px-0"
          data-test-id="amount-input"
        />

        <ui-text-area
          :value="description"
          :placeholder="$labels.InvoiceDetailsTab.payments.modal.comments_placeholder"
          :label="$labels.InvoiceDetailsTab.payments.modal.comments"
          name="description"
          rows="4"
          class="col-12 mt-4 px-0"
          data-test-id="description-input"
          @changevalue="({ detail }) => description = detail"
        />
      </MuiValidationWrapper>
    </div>

    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('on:close')"
      />

      <ui-button
        :disabled="!isSaveAllowed"
        data-test-id="save-button"
        @clickbutton="onEditPayment"
      >
        {{ $labels.Common.Actions.save }}
      </ui-button>
    </div>
  </MuiModal>
</template>
